import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles, CircularProgress } from "@material-ui/core"
import { Divider, Portlet } from "@/components"

const useStyles = makeStyles((theme) => ({
	portlet: {
		padding: `${theme.spacing(8)}px 20%`
	}
}))
const ProcessingPaymentSkeleton = () => {
	const classes = useStyles()

	return (
		<Portlet className={classes.portlet}>
			<Grid container alignItems="center" direction="column" spacing={4}>
				<Grid item>
					<CircularProgress size={128} thickness={2.7} />
				</Grid>

				<Divider orientation="horizontal" size={6} />

				<Skeleton
					variant="rect"
					width="80%"
					height="20px"
					animation="wave"
				/>

				<Divider orientation="horizontal" size={2} />

				<Skeleton
					variant="rect"
					width="100%"
					height="20px"
					animation="wave"
				/>
			</Grid>
		</Portlet>
	)
}

export default ProcessingPaymentSkeleton
