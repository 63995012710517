export default {
	field_cannot_be_empty: "O campo não pode ser vazio",
	invalid_format_email: "Email inválido",
	account_email_not_exist: "Usuário não cadastrado",
	account_email_exist: "Usuário já cadastrado no sistema",
	password_confirmation_must_match_password: "A senha está diferente da confirmação",

	mandatory_terms_of_use: "Termo de uso obrigatório",

	password_is_invalid: "Senha inválida",
	number_of_characters_less_than_eight: "Deve conter 8 ou mais caracteres",
	must_have_a_lowercase_character: "Deve conter ao menos uma letra minuscula",
	must_have_a_uppercase_character: "Deve conter ao menos uma letra maiuscula",
	must_have_a_number: "Deve conter ao menos um numero",
	must_have_a_special_character: "Deve conter ao menos um caractere especial [@?/*&!]",
	token_is_invalid_or_is_expired: "O token é inválido ou expirou",
	number_is_not_valid: "O número não é válido",
	invalid_format_cnpj: "CNPJ inválido"
}
