import React from "react"
import { Route, Switch } from "react-router-dom"
import { Box, Container, Grid, Typography } from "@material-ui/core"

import CheckoutSelectPlanAndPayment from "@/pages/Checkout/SelectPlanAndPayment"
import CheckoutProcessingPayment from "@/pages/Checkout/ProcessingPayment"
import CheckoutSubscriptionActivated from "@/pages/Checkout/SubscriptionActivated"
import InboxPlans from "@/pages/Plans/Inbox"

import WizardApp from "@/pages/WizardApp"
import Profile from "@/pages/Profile"

import { useGlobalStateStore } from "@/store/GlobalState"

import useStyles from "@/routes/styles"
import { Divider, Header } from "@/components"

const PrivateRoutes = () => {
	const globalStateStore = useGlobalStateStore()

	const accountStore = globalStateStore.account

	const classes = useStyles()

	return (
		<Container disableGutters maxWidth={false} style={{ minHeight: "100vh" }}>
			<Header />

			<Divider size={3} orientation="horizontal" />

			<Container>
				<Switch>
					<Route exact path="/" component={() => <h1>A private route</h1>} />
					<Route exact path="/wizard-app" component={WizardApp} />
					<Route exact path="/inbox/plans" component={InboxPlans} />
					<Route exact path="/checkout/processing-payment/:subscription_id" component={CheckoutProcessingPayment} />
					<Route exact path="/checkout/subscription-activated" component={CheckoutSubscriptionActivated} />
					<Route exact path="/checkout" component={CheckoutSelectPlanAndPayment} />
					<Route exact path="/profile" component={Profile} />
					<Route path="*" component={() => <h1>Page Not Found</h1>} />
				</Switch>
			</Container>

			<Divider size={3} orientation="horizontal" />

			{
				accountStore?.extra_data?.is_admin_mode &&
				<Box
					component="span"
					className={classes.isAdminMode}
				>
					<Grid
						container
						justify="center"
						alignItems="center"
						style={{
							height: "100%"
						}}
					>
						<Grid item>
							<Typography
								variant="inherit"
								align="center"
								color="inherit"
							>
								Modo administrador
							</Typography>
						</Grid>
					</Grid>

				</Box>
			}
		</Container>
	)
}

export default PrivateRoutes
