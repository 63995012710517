import React from "react"

import logoImg from "@/assets/images/logos/letalk-small-logo-white.svg"

import newColors from "@/styles/newColors"

import PortletWithSplitContent from "@/components/PortletWithSplitContent"
import { Typography } from "@material-ui/core"
import { RouteComponentProps } from "react-router-dom"
import { formatPartiallyHiddenEmail } from "@/utils/mask"

type UserCreatedPageProps = RouteComponentProps<{}, {}, {
	email?: string
}>

const UserCreatedPage: React.FC<UserCreatedPageProps> = (props) => {
	const userEmail = String(props?.location?.state?.email)
	const partialllyHiddenEmail = formatPartiallyHiddenEmail(userEmail)

	const userInviteSentText = partialllyHiddenEmail ? `Clique no link enviado para o email ${partialllyHiddenEmail} e acesse a Letalk!` : "Clique no link enviado para o seu email e acesse a Letalk!"

	return (
		<PortletWithSplitContent
			title={{
				value: "Usuário criado",
				logo: logoImg,
				backgroundColor: newColors.green[500],
				textColor: newColors.grey[0]
			}}
			body={{
				backgroundColor: newColors.grey[0]
			}}
		>
			<Typography
				align="center"
				variant="subtitle1"
			>
				{ userInviteSentText }
			</Typography>
		</PortletWithSplitContent>
	)
}

export default UserCreatedPage
