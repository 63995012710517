import React from "react"
import { Divider, InputDialog } from "@/components"
import { isSmallScreen } from "@/utils/checkDevice"
import { Typography, Grid, List, TextField, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core"
import { ArrowForwardIos as ArrowForwardIosIcon } from "@material-ui/icons"

import ApiService from "@/services/Api"
import { AccountData } from "@/services/Account"

import useValidation from "@/hooks/useValidation"

import useStyles from "@/pages/Profile/Account/styles"
import { useGlobalStateStore } from "@/store/GlobalState"

const Account = () => {
	const classes = useStyles()
	const { validation, triggerValidation, clearValidation } = useValidation()

	const globalStateStore = useGlobalStateStore()

	const accountStore = globalStateStore.account

	const handleChangeAccountData = async (field: keyof AccountData, value: string) => {
		try {
			await ApiService.put("/account", { [field]: value })

			globalStateStore.setAccountData({
				[field]: value
			})

			return true
		} catch (err) {
			triggerValidation(err)
			return false
		}
	}

	const clearAccountDataValidation = (field: keyof AccountData) => {
		clearValidation(field)
	}

	return (
		<Grid container >
			<Grid item xs={12}>
				<Typography variant="h3">
          Meus dados
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<Divider orientation="horizontal" size={2} />
			</Grid>

			<Grid item xs={12}>
				<List >
					<InputDialog
						title="Mudar nome"
						onOk={(value) => handleChangeAccountData("name", value as string)}
						onDataChange={() => { clearAccountDataValidation("name") }}
						initialValue={accountStore?.name || ""}
						customInputElement={(
							<TextField
								label="Nome"
								variant="standard"
								color="secondary"
								multiline
								fullWidth
								error={!!validation.name}
								helperText={validation.name}
							/>
						)}
						openOnDrawer={isSmallScreen}
						fullWidth
					>
						<ListItem button className={classes.listItem}>
							<ListItemText
								primary={accountStore?.name}
								primaryTypographyProps={{
									variant: "h3",
									color: "textPrimary",
									noWrap: true
								}}
							/>
							<ListItemSecondaryAction className={classes.nextIcon}>
								<IconButton>
									<ArrowForwardIosIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					</InputDialog>

					<InputDialog
						title="Mudar e-mail"
						onOk={(value) => handleChangeAccountData("email", value as string)}
						onDataChange={() => { clearAccountDataValidation("email") }}
						initialValue={accountStore?.email || ""}
						customInputElement={(
							<TextField
								label="E-mail"
								variant="standard"
								color="secondary"
								multiline
								fullWidth
								error={!!validation.email}
								helperText={validation.email}
							/>
						)}
						openOnDrawer={isSmallScreen}
						fullWidth
					>
						<ListItem button className={classes.listItem}>
							<ListItemText
								primary={accountStore?.email}
								primaryTypographyProps={{
									variant: "h3",
									color: "textPrimary",
									noWrap: true
								}}
							/>
							<ListItemSecondaryAction className={classes.nextIcon}>
								<IconButton>
									<ArrowForwardIosIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					</InputDialog>
				</List>
			</Grid>
		</Grid>
	)
}

export default Account
