import React from "react"
import PropTypes from "prop-types"
import { withStyles, StepConnector, makeStyles } from "@material-ui/core"
import { Check as CheckIcon } from "@material-ui/icons"
import colors from "@/styles/colors"

export const QontoConnector = withStyles({
	alternativeLabel: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)"
	},
	active: {
		"& $line": {
			borderColor: colors.palette.confirm
		}
	},
	completed: {
		"& $line": {
			borderColor: colors.palette.confirm
		}
	},
	line: {
		borderColor: colors.greyScale[8],
		borderTopWidth: 3,
		borderRadius: 1
	}
})(StepConnector)

export const useQontoStepIconStyles = makeStyles({
	root: {
		color: colors.greyScale[8],
		display: "flex",
		height: 22,
		alignItems: "center"
	},
	active: {
		color: colors.palette.confirm
	},
	circle: {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor"
	},
	completed: {
		color: colors.palette.confirm,
		zIndex: 1,
		fontSize: 18
	}
})

type QontoStepIconProps = {
	active?: boolean
	completed?: boolean
}

export function QontoStepIcon (props: QontoStepIconProps) {
	const classes = useQontoStepIconStyles()
	const { active, completed } = props

	return (
		<div
			className={`${classes.root} ${active && classes.active}`}
		>
			{completed ? <CheckIcon className={classes.completed} /> : <div className={classes.circle} />}
		</div>
	)
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool
}
