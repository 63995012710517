import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

export type AccountExtraData = {
	is_admin_mode: boolean
	is_tester?: boolean
}

export type AccountData = {
	id: number
	name: string
	email: string
	phone_number: number
	extra_data: AccountExtraData
}

class AccountService {
	async getInfo (): Promise<AccountData | null> {
		try {
			const { data } = await ApiService.get("/account")

			return data
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}
}

export default new AccountService()
