import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	appCardContainer: {
		width: "100%",
		maxWidth: "230px"
	},
	appCardSkeleton: {
		borderRadius: "8px"
	}
})

const AppCardSkeleton = () => {
	const classes = useStyles()

	const nCards = 3
	const cards = []

	for (let item = 0; item < nCards; item++) {
		cards.push(
			<Grid key={item} item xs={12} sm={6}>
				<Grid container justify="center">
					<Grid item className={classes.appCardContainer}>
						<Skeleton
							className={classes.appCardSkeleton} variant="rect" width="100%" height={"260px"} animation="wave" />
					</Grid>
				</Grid>
			</Grid>
		)
	}

	return (
		<Grid
			container
			justify="flex-start"
			alignContent="center"
			alignItems="center"
			spacing={1}
		>
			{ cards }
		</Grid>
	)
}

export default AppCardSkeleton
