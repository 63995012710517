import React from "react"
import { Button, Grid, Typography, ButtonGroup, useMediaQuery, useTheme } from "@material-ui/core"
import { PeriodicityOption, Plan } from "@/protocols/plan"
import { transformCentsInDefaultPattern } from "@/utils/money"
import { Portlet, Divider, CardSelect } from "@/components"
import colors from "@/styles/colors"

type PlansProps = {
	plans: Plan[]
	selectedPlan?: Plan
	handleChangeSelectedPlan: (planId: number) => void
	selectedPeriodicityInMonths: number
	handleChangeSelectedPeriodicityInMonths: (periodicityInMonths: number) => void
}

const Plans = ({
	plans,
	selectedPlan,
	handleChangeSelectedPlan,
	selectedPeriodicityInMonths,
	handleChangeSelectedPeriodicityInMonths
}: PlansProps) => {
	const theme = useTheme()
	const aboveSmBreakpoint = useMediaQuery(theme.breakpoints.up("sm"))
	const above448Px = useMediaQuery("(min-width:448px)")

	const getCardWidth = () => {
		if (aboveSmBreakpoint) {
			return "232px"
		}

		if (above448Px) {
			return "50%"
		}

		return "100%"
	}

	const renderPlanOptionCard = (plan: Plan, periodicityInMonths: number) => {
		const totalPriceInCents = plan.price_in_cents * periodicityInMonths
		const periodicityOption = plan.periodicity_options.find(option => option.periodicity_in_months === periodicityInMonths) as PeriodicityOption
		const installmentCounts = periodicityOption.installment_options.map(option => option.count) as number[]
		const maxInstallmentCount = Math.max(...installmentCounts)

		return (
			<Grid
				container
				alignItems="center"
				direction="column"
			>
				<Typography variant="h1">
					{plan?.display_name}
				</Typography>

				{periodicityInMonths > 1 && (
					<>
						<Divider orientation="horizontal" size={2} />

						<Typography variant="overline" align="center" style={{ color: colors.palette.link }}>
							Cobrado R$ {transformCentsInDefaultPattern(totalPriceInCents)} por ano, dividido em até {maxInstallmentCount}x
						</Typography>
					</>
				)}

				<Divider orientation="horizontal" size={4} />

				<Grid item>
					<Grid container alignItems="flex-end">
						<Typography variant="body2">
							R$
						</Typography>

						<Divider orientation="vertical" size={0.5} />

						<Typography variant="h1">
							{transformCentsInDefaultPattern(+plan?.price_in_cents)}
						</Typography>
					</Grid>
				</Grid>

				<Divider orientation="horizontal" size={0.375} />

				<Typography variant="body2">
					por mês
				</Typography>

				<Divider orientation="horizontal" size={3} />

				{plan.limits.users && (
					<Typography variant="body2">
						{plan.limits.users} usuário{plan.limits.users > 1 ? "s" : ""}
					</Typography>
				)}
			</Grid>
		)
	}

	return (
		<Portlet elevation={1}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h3">
						Plano
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<ButtonGroup color="primary">
						<Button
							disableElevation
							variant={selectedPeriodicityInMonths === 1 ? "contained" : "outlined"}
							onClick={() => handleChangeSelectedPeriodicityInMonths(1)}
						>
							Mensal
						</Button>

						<Button
							disableElevation
							variant={selectedPeriodicityInMonths === 12 ? "contained" : "outlined"}
							onClick={() => handleChangeSelectedPeriodicityInMonths(12)}
						>
							Anual
						</Button>
					</ButtonGroup>
				</Grid>

				<Grid item xs={12}>
					<CardSelect
						items={plans.map(plan => ({
							value: plan.id,
							data: plan,
							isDisabled: plan.already_own,
							disabledMessage: "Você já possui esse plano"
						}))}
						value={selectedPlan?.id}
						renderOptions={{
							periodicityInMonths: selectedPeriodicityInMonths
						}}
						render={({ data }, options) => renderPlanOptionCard(data as Plan, options?.periodicityInMonths as number)}
						onChange={handleChangeSelectedPlan}
						width={getCardWidth()}
						padding="24px 16px"
					/>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default Plans
