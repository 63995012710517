import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles((theme) => ({
	listItem: {
		backgroundColor: colors.greyScale[11],
		margin: theme.spacing(1, 0),
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5)
	},
	nextIcon: {
		color: colors.greyScale[5],
		minWidth: "0px"
	}
}))

export default useStyles
