import { Divider } from "@/components"
import { Container, Divider as MuiDivider } from "@material-ui/core"
import React from "react"
import Account from "@/pages/Profile/Account"
import Subscriptions from "@/pages/Profile/Subscriptions"

const Profile = () => {
	return (
		<Container maxWidth="md" disableGutters>
			<Account />

			<Divider orientation="horizontal" size={3} />

			<MuiDivider light />

			<Divider orientation="horizontal" size={3} />

			<Subscriptions />
		</Container >
	)
}

export default Profile
