import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	portlet: {
		padding: `${theme.spacing(6)}px 10%`
	},
	checkCircleIcon: {
		fontSize: "6rem",
		border: `4px solid ${colors.palette.confirm}`,
		borderRadius: "50%"

	}
}))

export default useStyles
