import React, { useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import ApiService from "@/services/Api"
import useDidMount from "@/hooks/useDidMount"
import useValidation from "@/hooks/useValidation"
import { Container } from "@material-ui/core"
import { Divider, Loading, Portlet } from "@/components"
import CheckoutStepper from "@/pages/Checkout/Stepper"
import { PaymentMethod } from "@/protocols/payment"
import CreditCard from "@/pages/Checkout/ProcessingPayment/CreditCard"
import BankSlip from "@/pages/Checkout/ProcessingPayment/BankSlip"
import ProcessingPaymentSkeleton from "@/skeletons/Checkout/ProcessingPayment"
import { Invoice } from "@/pages/Checkout/ProcessingPayment/protocols"
import useStyles from "@/pages/Checkout/ProcessingPayment/styles"

const CheckoutProcessingPayment = () => {
	const { subscription_id } = useParams()
	const history = useHistory()
	const classes = useStyles()

	const [invoiceData, setInvoiceData] = useState<Invoice>()
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>()
	const [loadingSubscriptionData, setLoadingSubscriptionData] = useState(true)

	const { triggerValidation } = useValidation()

	const fetchAndUpdateSubscriptionData = async (subscriptionId: number) => {
		try {
			const { data } = await ApiService.get(`/subscription/billing/${subscriptionId}`)

			if (data?.subscription?.expiration_date) {
				history.push("/checkout/subscription-activated")
			}

			setInvoiceData(data?.invoice)
			setPaymentMethod(data?.invoice.payable_with)

			setLoadingSubscriptionData(false)

			setTimeout(async () => await fetchAndUpdateSubscriptionData(subscriptionId), 5000)
		} catch (err) {
			triggerValidation(err)
		}
	}

	useDidMount(() => {
		fetchAndUpdateSubscriptionData(subscription_id)
	})

	return (
		<Container maxWidth="md" disableGutters	>
			<Divider orientation="horizontal" size={4} />

			<CheckoutStepper activeStep={1} />

			<Divider orientation="horizontal" size={4} />

			<Loading
				loading={loadingSubscriptionData}
				customLoadingElement={<ProcessingPaymentSkeleton />}
			>
				<Portlet className={classes.portlet} elevation={1}>
					{paymentMethod === "credit_card" && <CreditCard />}

					{paymentMethod === "bank_slip" && <BankSlip invoice={invoiceData} />}
				</Portlet>
			</Loading>

			<Divider orientation="horizontal" size={6} />
		</Container >
	)
}

export default CheckoutProcessingPayment
