import React from "react"
import { Route } from "react-router-dom"

import Typography from "@/pages/Development/Typography"
import Palette from "@/pages/Development/Palette"
import SignupTester from "@/pages/Development/Auth/SignUp"

const DevelopmentRoutes = [
	<Route
		exact
		key="typography"
		path="/dev/typography"
		component={Typography}
	/>,
	<Route
		exact
		key="palette"
		path="/dev/palette"
		component={Palette}
	/>,
	<Route
		exact
		key="signup-tester"
		path="/dev/!btps@/signup"
		component={SignupTester} />
]

export default DevelopmentRoutes
