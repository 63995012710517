import React from "react"

import PhoneInput, { PhoneInputProps } from "react-phone-input-2"
import "@/components/PhoneInput/styles.css"
import { FormControl, FormHelperText } from "@material-ui/core"

export interface CountryData {
	name: string;
	dialCode: string;
	countryCode: string;
	format: string;
}

export type PhoneNumberType = {
	value: string | null
	countryCode?: string | null
}

interface PhoneInputComponent extends PhoneInputProps{
	validation: string
}

const PhoneInputComponent = (props: PhoneInputComponent) => {
	const {
		value,
		specialLabel,
		country,
		preferredCountries,
		placeholder,
		validation,
		disabled,
		onChange
	} = props

	return (
		<FormControl
			error={!!validation}
			fullWidth
		>
			<PhoneInput
				value={value}
				specialLabel={specialLabel}
				country={country}
				preferredCountries={preferredCountries}
				onChange={onChange}
				placeholder={placeholder}
				inputProps={{
					id: "input-phone",
					name: "phone_number",
					placeholder
				}}
				inputStyle={{
					width: "100%"
				}}
				containerStyle={
					validation ? {
						color: "#f44336"
					} : undefined
				}
				isValid={!validation}
				disabled={disabled}
			/>
			<FormHelperText id="phone-number-helper-text" variant="outlined">
				{validation}
			</FormHelperText>
		</FormControl>
	)
}

export default PhoneInputComponent
