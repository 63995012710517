import React, { useState } from "react"
import {
	Container,
	Grid,
	InputAdornment,
	IconButton,
	Button,
	Typography,
	Link,
	CircularProgress,
	TextField,
	Checkbox,
	FormControlLabel,
	FormControl,
	FormHelperText,
	FormGroup
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import { Divider, Portlet } from "@/components"
import useStyles from "@/pages/Auth/styles"
import ApiService from "@/services/Api"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import AuthService from "@/services/Auth"

import logoImg from "@/assets/images/logos/letalk-logo.svg"
import PhoneInputComponent, { CountryData, PhoneNumberType } from "@/components/PhoneInput"
import { inboxAppUrl } from "@/config/url"
import { formatEmail } from "@/utils/mask"
import { onlyLettersNumbersAndAccents } from "@/utils/string"
import { isDevEnv } from "@/utils/environment"
import useQuery from "@/hooks/useQuery"
import axios from "axios"
import useDidMount from "@/hooks/useDidMount"
import { useHistory } from "react-router-dom"
import { letalkLinks } from "@/utils/link"

type SignUpDataType = {
	name: string | null
	email: string | null
	password: string | null
	phoneNumber: PhoneNumberType
	confirmPassword: string | null
	termsOfServiceAgreement: boolean
	paymentIdentification?: {
		email: string | null
	}
}

const TrialSignUp = () => {
	const query = useQuery()
	const history = useHistory()

	const paymentIdentificationEmail = query.get("email")

	const userName = query.get("userName")
	const userEmail = query.get("userEmail")
	const userPhone = query.get("userPhone")

	const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
	const [signUpData, setSignUpData] = useState<SignUpDataType>({
		name: userName,
		email: userEmail,
		phoneNumber: {
			value: userPhone,
			countryCode: "br"
		},
		password: null,
		confirmPassword: null,
		termsOfServiceAgreement: false,
		paymentIdentification: {
			email: paymentIdentificationEmail
		}
	})

	const [loadingSignUp, setLoadingSignUp] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const classes = useStyles()

	const handleSignUpDataChange = (type: string, value: string | boolean | PhoneNumberType) => {
		setSignUpData((currentState) => ({ ...currentState, [type]: value }))

		clearValidation(type)
	}

	const handleSubmitSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoadingSignUp(true)

		try {
			const WebhookCaller = axios.create({
				baseURL: "https://webhook.letalk.com.br",
				withCredentials: true
			})

			const { data } = await ApiService.post("/auth/signup", {
				name: signUpData.name,
				email: signUpData.email,
				phoneNumber: signUpData.phoneNumber,
				password: signUpData.password,
				confirmPassword: signUpData.password,
				termsOfServiceAgreement: signUpData.termsOfServiceAgreement,
				paymentIdentification: {
					email: signUpData.paymentIdentification?.email
				}
			})

			await WebhookCaller.post("/b6ff5a1e-2d31-4153-88d7-6ba11eaf8b28", {
				contact: {
					name: signUpData.name,
					email: signUpData.email,
					phone: signUpData.phoneNumber.value,
					fields: {
						status_cliente: "Novo Cliente"
					}
				}
			})

			if (data) {
				const { data } = await ApiService
					.post("/auth/signin", {
						email: signUpData.email,
						password: signUpData.password,
						context_url: {
							desiredContext: {
								// ...((app_code && app_code !== "null") && { relatedApplication: app_code })
								relatedApplication: "inbox_app"
							},
							currentContext: {
								relatedApplication: "account",
								pathUrl: window.location.href
							}
						}
					})

				const {
					redirectPathUrl
				} = data

				AuthService.login(redirectPathUrl)
			}
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoadingSignUp(false)
	}

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const handlePhoneNumberChange = (value: string, country?: CountryData) => {
		handleSignUpDataChange("phoneNumber", {
			value,
			...(country && { countryCode: country.countryCode })
		})
	}

	useDidMount(() => {
		query.delete("userEmail")
		query.delete("userPhone")
		query.delete("userName")

		history.replace({
			search: query.toString()
		})
	})

	return (
		<Container maxWidth={"md"}>
			<Grid container justify="center" className={classes.authContainer}>
				<Grid item xs={12} sm={10} md={9}>
					<Grid container justify="center" className={classes.authContent}>
						<Grid item xs={12} sm={10} md={9} className={classes.authWrapper}>
							<Portlet elevation={1} className={classes.portlet}>
								<img src={logoImg} alt="Letalk" className={classes.logoImg} />

								<Divider size={4} orientation="horizontal" />

								<Grid container component="form" onSubmit={handleSubmitSignUp}>
									<Grid item xs={12}>
										<TextField
											id="input-name"
											name="name"
											value={signUpData.name}
											disabled
											onChange={
												({ target }) => handleSignUpDataChange(
													"name",
													onlyLettersNumbersAndAccents(target.value)
												)
											}
											variant="outlined"
											label="Nome completo"
											fullWidth
											helperText={validation.name}
											error={!!validation.name}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={3} />
									</Grid>

									<Grid
										item
										xs={12}
									>

										<TextField
											id="input-email"
											name="email"
											value={signUpData.email}
											disabled
											onChange={
												({ target }) => handleSignUpDataChange(
													"email", formatEmail(target.value)
												)
											}
											variant="outlined"
											label="E-mail"
											fullWidth
											helperText={validation.email}
											error={!!validation.email}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={3} />
									</Grid>

									<Grid
										hidden={true}
										item
										xs={12}
									>

										<TextField
											disabled
											id="input-payment-identification-email"
											name="payment_identification_email"
											value={signUpData.paymentIdentification?.email}
											variant="outlined"
											label="E-mail da conta de pagamento"
											fullWidth
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={3} />
									</Grid>

									<Grid
										item
										xs={12}
									>
										<PhoneInputComponent
											value={signUpData?.phoneNumber?.value}
											specialLabel="Celular"
											country={"br"}
											preferredCountries={["br", "pt", "us"]}
											onChange={(phoneNumber, countryCode) => handlePhoneNumberChange(phoneNumber, countryCode as CountryData)}
											validation={validation.phoneNumber}
											disabled
										/>

										<Divider orientation="horizontal" size={3} />
									</Grid>

									<Grid item xs={12}>
										<TextField
											id="input-password"
											type={visiblePassword ? "text" : "password"}
											value={signUpData.password}
											onChange={
												({ target }) => handleSignUpDataChange("password", target.value)
											}
											label="Senha"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="alterar visibilidade da senha"
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															edge="end"
														>
															{visiblePassword ? <ShowPassword /> : <HiddenPassword />}
														</IconButton>
													</InputAdornment>
												)
											}}
											fullWidth
											variant="outlined"
											helperText={validation.password}
											error={validation.password}
										/>
									</Grid>

									<Divider orientation="horizontal" size={2} />

									<Grid item xs={12}>
										<FormControl
											error={!!validation.termsOfServiceAgreement}
											component="fieldset"
										>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={signUpData.termsOfServiceAgreement}
															onChange={
																({ target }) => handleSignUpDataChange(
																	"termsOfServiceAgreement", target.checked
																)}
															name="termsOfServiceAgreement"
															color="primary"
														/>}
													label={
														<>
															<Typography>
																Li e aceito os {" "}
																<Link
																	href={letalkLinks.notionTermsOfService}
																	target="_blank"
																	underline="always">
																	Termos de Uso
																</Link>
																{" "} da Letalk
															</Typography>
														</>
													}
												/>
											</FormGroup>
											<FormHelperText id="according-terms-helper-text" variant="outlined">
												{validation.termsOfServiceAgreement}
											</FormHelperText>
										</FormControl>
									</Grid>

									<Divider orientation="horizontal" size={2} />

									<Grid item xs={12}>
										<Button
											color="primary"
											variant="contained"
											type='submit'
											disabled={loadingSignUp}
											endIcon={loadingSignUp && (
												<CircularProgress size={20} color="inherit" />
											)}
											fullWidth
											className={classes.submitButton}
										>
											Cadastrar
										</Button>
									</Grid>
								</Grid>

								<Divider orientation="horizontal" size={1.5} />
								<Typography align="center">
									Já possui cadastro?{" "}
									<Link
										href={inboxAppUrl.cpURL}
										underline="always">
										Acessar
									</Link>
								</Typography>
							</Portlet>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

export default TrialSignUp
