import React, { ReactNode } from "react"
import { ButtonBase, Grid, Badge, Card, Avatar, Tooltip } from "@material-ui/core"

import { Done as DoneIcon } from "@material-ui/icons"

import useStyles from "@/components/CardSelect/styles"

type Item<ItemValue extends unknown> = {
	value: string | number
	isDisabled?: boolean
	disabledMessage?: string
	data?: ItemValue
}

type CardSelectProps<ItemValue extends unknown, Value extends string | number, RenderOptions extends unknown> = {
	value?: Value
	items: Item<ItemValue>[],
	onChange: (value: Value) => void
	renderOptions?: RenderOptions
	render: (item: Item<ItemValue>, options?: RenderOptions) => ReactNode
	padding?: string
	width?: string
}

function CardSelect<
	ItemValue extends unknown,
	Value extends string | number,
	RenderOptions extends unknown
> (props: CardSelectProps<ItemValue, Value, RenderOptions>) {
	const {
		value,
		items,
		onChange,
		renderOptions,
		render,
		padding,
		width
	} = props

	const classes = useStyles({ padding, width })

	// eslint-disable-next-line
	const voidFunction = () => { }

	return (
		<Grid
			container
			spacing={2}
			alignItems="stretch"
		>
			{items?.map(item => {
				const isActive = item.value === value
				const isDisabled = item.isDisabled
				const badgeClassName = `${classes.badge} ${isActive && classes.badgeIsActive}`
				const cardClassName = `${classes.card} ${isActive && classes.cardIsActive} ${isDisabled && classes.cardIsDisabled}`

				const onClick = isDisabled ? voidFunction : onChange

				return (
					<Grid
						key={item.value}
						item
						className={classes.gridItem}
					>
						<Tooltip
							title={(isDisabled && item?.disabledMessage) || ""}
							placement="bottom"
						>
							<Badge
								badgeContent={
									<Avatar className={classes.badgeAvatar}>
										<DoneIcon />
									</Avatar>
								}
								className={badgeClassName}
								invisible={!isActive}
							>
								<Card
									elevation={0}
									onClick={() => onClick(item.value as Value)}
									component={ButtonBase}
									className={cardClassName}
								>
									{render(item, renderOptions)}
								</Card>
							</Badge>
						</Tooltip>
					</Grid>
				)
			})}
		</Grid >
	)
}

export default CardSelect
