import React from "react"
import { Button, Grid, Typography } from "@material-ui/core"
import { Divider, Notification } from "@/components"
import { FileCopy as CopyIcon } from "@material-ui/icons"
import useStyles from "@/pages/Checkout/ProcessingPayment/BankSlip/styles"
import { Invoice } from "@/pages/Checkout/ProcessingPayment/protocols"

type BankSlipProps = {
	invoice?: Invoice
}

const BankSlip = ({ invoice }: BankSlipProps) => {
	const digitableLine = invoice?.bank_slip?.digitable_line || ""

	const classes = useStyles()

	const handleShowBankSlip = () => {
		const invoiceUrl = invoice?.secure_url

		if (invoiceUrl) {
			window.open(
				invoiceUrl,
				"_blank"
			)
		}
	}

	const handleCopyBarcode = (barcode: string) => {
		navigator.clipboard.writeText(barcode)
		Notification.success({
			message: "Código de barras copiado!"
		})
	}

	return (
		<Grid container alignItems="center" direction="column" spacing={6}>
			<Grid item >
				<Typography variant="h2" align="center">
					O seu boleto foi gerado!
				</Typography>
			</Grid>

			<Grid item >
				<Typography variant="h4" align="center" className={classes.attentionText}>
					ATENÇÃO:
				</Typography>

				<Divider orientation="horizontal" size={1} />

				<Typography variant="body1" align="center">
					Caso não consiga efetuar o pagamento do boleto imediatamente, aguarde um período de 2 horas, é o tempo que o boleto leva para ser registrado no banco central.
				</Typography>
			</Grid>

			<Grid item >
				<Typography variant="body1" align="center">
					Após o pagamento, o banco pode levar até 72h para nos informar do pagamento, assim que o banco reconhecer o pagamento será ativado imediatamente.
				</Typography>
			</Grid>

			{
				digitableLine && (
					<Grid item>
						<Button
							onClick={() => handleCopyBarcode(digitableLine)}
							startIcon={<CopyIcon />}
							variant="text"
							color="secondary"
						>
							Copiar código de barras
						</Button>
					</Grid>
				)
			}

			<Grid item>
				<Button variant="contained" color="primary" onClick={handleShowBankSlip}>
					Ver boleto
				</Button>
			</Grid>
		</Grid>
	)
}

export default BankSlip
