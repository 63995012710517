import axios from "axios"
import axiosRetry from "axios-retry"

import apiConfig from "@/config/api"

const ApiService = axios.create({
	baseURL: apiConfig.apiUrl,
	withCredentials: true
})

axiosRetry(ApiService, {
	retries: 5,
	retryDelay: (retryCount) => 1000 * retryCount
})

export default ApiService

export const InboxApiService = axios.create({
	baseURL: apiConfig.inboxApiUrl,
	withCredentials: true
})

axiosRetry(InboxApiService, {
	retries: 5,
	retryDelay: (retryCount) => 1000 * retryCount
})
