import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	authContainer: {
		height: "100%",
		justifyContent: "center"
	},
	authContent: {
		height: "100%"
	},
	authWrapper: {
		margin: "auto 0"
	},
	portlet: {
		padding: `${theme.spacing(5)}px 10%`
	},
	logoImg: {
		display: "flex",
		margin: "0 auto",
		height: 50
	}
}))

export default useStyles
