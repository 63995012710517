import React, { createContext, useContext, useState } from "react"
import { AccountData } from "@/services/Account"

export interface GlobalStateContextData {
	account: AccountData
	setAccountData: (data: Partial<AccountData>) => void
}

const GlobalStateStore = createContext<GlobalStateContextData>({} as GlobalStateContextData)

export const useGlobalStateStore = () => useContext(GlobalStateStore)

const GlobalStateProvider: React.FC = (props) => {
	const { children } = props

	const [account, setAccount] = useState<AccountData>({} as AccountData)

	const setAccountData = (
		data: Partial<AccountData>
	) => {
		setAccount(lastState => ({
			...lastState,
			...data
		}))
	}

	return (
		<GlobalStateStore.Provider
			value={{
				account,
				setAccountData
			}}
		>
			{children}
		</GlobalStateStore.Provider>
	)
}

export default GlobalStateProvider
