import CookieService from "@/services/Cookie"
import AccountService from "@/services/Account"
import ApiService, { InboxApiService } from "@/services/Api"

import authConfig from "@/config/auth"
import { Notification } from "@/components"
import { inboxAppUrl } from "@/config/url"

class AuthService {
	get authTokenKey (): string {
		return authConfig.authTokenKey
	}

	get authToken (): string | null {
		const authToken = CookieService.get(this.authTokenKey)

		return authToken
	}

	async isLoggedIn (): Promise<boolean> {
		const account = await AccountService.getInfo()

		return Boolean(account)
	}

	login (callbackHref?: string): void {
		window.location.replace(callbackHref || "/wizard-app")
	}

	async logout (): Promise<void> {
		try {
			await ApiService.delete("/auth/logout")

			window.location.assign(`${inboxAppUrl.cpURL}/signin`)
		} catch (error) {
			Notification.error({ message: "Algo deu errado e não conseguimos identificar. Entre em contato." })
		}
	}

	// This functionality will be temporary,
	// just to fix the problem of retroactive users (BUME 1.0)
	async containInMailingOldBume (email: string | null): Promise<boolean | void> {
		try {
			const { data: containInMailingOldBume } = await InboxApiService
				.get("/mailing-old-bume/check", {
					params: {
						email
					}
				})

			if (containInMailingOldBume) {
				return true
			} else {
				return false
			}
		} catch (error) {
			Notification
				.error({
					message: "Algo deu errado e não conseguimos identificar. Entre em contato."
				})
		}
	}
}

export default new AuthService()
