import React, { useState } from "react"
import {
	Container,
	Grid,
	InputAdornment,
	IconButton,
	Button,
	CircularProgress,
	TextField
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import { Divider } from "@/components"
import useStyles from "@/pages/Auth/styles"
import ApiService from "@/services/Api"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import AuthService from "@/services/Auth"
import useQuery from "@/hooks/useQuery"

type SignUpDataType = {
	name: string | null
	email: string | null
	password: string | null
	confirmPassword: string | null
	termsOfServiceAgreement: boolean
	isTester: boolean
}

const SignUp = () => {
	const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
	const [signUpData, setSignUpData] = useState<SignUpDataType>({
		name: null,
		email: null,
		password: null,
		confirmPassword: null,
		termsOfServiceAgreement: true,
		isTester: true
	})
	const [loadingSignUp, setLoadingSignUp] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const classes = useStyles()
	const query = useQuery()

	const app_code = query.get("app_code")

	const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoadingSignUp(true)

		try {
			const { data } = await ApiService.post("/auth/signup", {
				name: signUpData.name,
				email: signUpData.email,
				password: signUpData.password,
				confirmPassword: signUpData.password,
				termsOfServiceAgreement: true,
				isTester: true
			})

			if (data) {
				const { data } = await ApiService
					.post("/auth/signin", {
						email: signUpData.email,
						password: signUpData.password,
						context_url: {
							desiredContext: {
								relatedApplication: app_code
							},
							currentContext: {
								relatedApplication: "account",
								pathUrl: window.location.href
							}
						}
					})

				const {
					redirectPathUrl
				} = data

				AuthService.login(redirectPathUrl)
			}
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoadingSignUp(false)
	}

	const handleSignUpDataChange = (type: string, value: string | boolean) => {
		setSignUpData({ ...signUpData, [type]: value })

		clearValidation(type)
	}

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	return (
		<Container maxWidth={"md"}>
			<Grid container justify="center" className={classes.authContainer}>
				<Grid item xs={8}>
					<Grid container justify="center" className={classes.authContent}>
						<Grid item xs={8} className={classes.authWrapper}>
							<form onSubmit={handleSignUp}>
								<TextField
									id="input-name"
									name="name"
									value={signUpData.name}
									onChange={
										({ target }) => handleSignUpDataChange("name", target.value)
									}
									variant="outlined"
									label="Nome"
									fullWidth
									helperText={validation.name}
									error={!!validation.name}
								/>

								<Divider orientation="horizontal" size={3} />

								<TextField
									id="input-email"
									name="email"
									value={signUpData.email}
									onChange={
										({ target }) => handleSignUpDataChange("email", target.value)
									}
									variant="outlined"
									label="Email"
									fullWidth
									helperText={validation.email}
									error={!!validation.email}
								/>

								<Divider orientation="horizontal" size={3} />

								<TextField
									id="input-password"
									type={visiblePassword ? "text" : "password"}
									value={signUpData.password}
									onChange={
										({ target }) => handleSignUpDataChange("password", target.value)
									}
									label="Senha"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="alterar visibilidade da senha"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{visiblePassword ? <ShowPassword /> : <HiddenPassword />}
												</IconButton>
											</InputAdornment>
										)
									}}
									fullWidth
									variant="outlined"
									helperText={validation.password}
									error={!!validation.password}
								/>

								<Divider orientation="horizontal" size={6} />

								<Button
									color="primary"
									variant="contained"
									type='submit'
									disabled={loadingSignUp}
									endIcon={loadingSignUp && (
										<CircularProgress size={20} color="inherit" />
									)}
									fullWidth
								>
									Cadastrar
								</Button>
								<Divider orientation="horizontal" size={1.5} />
							</form>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

export default SignUp
