import React, { useState } from "react"
import {
	Container,
	Grid,
	InputAdornment,
	IconButton,
	Button,
	Typography,
	Link,
	CircularProgress,
	TextField,
	Checkbox,
	FormControlLabel,
	FormControl,
	FormHelperText,
	FormGroup,
	Box
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import { Divider, Portlet } from "@/components"
import useStyles from "@/pages/Auth/styles"
import ApiService from "@/services/Api"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import logoImg from "@/assets/images/logos/letalk-logo.svg"
import PhoneInputComponent, { CountryData, PhoneNumberType } from "@/components/PhoneInput"
import { inboxAppUrl } from "@/config/url"
import { formatEmail } from "@/utils/mask"
import { onlyLettersNumbersAndAccents } from "@/utils/string"
import { isDevEnv } from "@/utils/environment"
import useQuery from "@/hooks/useQuery"
import axios from "axios"
import { letalkLinks } from "@/utils/link"
import { useHistory } from "react-router-dom"

type SignUpDataType = {
	cnpj: string | null
	name: string | null
	email: string | null
	password: string | null
	phoneNumber: PhoneNumberType
	confirmPassword: string | null
	termsOfServiceAgreement: boolean
	paymentIdentification?: {
		email: string | null
	}
	cep: string | null
	address: string | null
	address_number: string | null
	address_complement: string | null
	neighborhood: string | null
	city: string | null
	state: string | null
}

const SignUp = () => {
	const query = useQuery()

	const paymentIdentificationEmail = query.get("email")

	const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
	const [signUpData, setSignUpData] = useState<SignUpDataType>({
		cnpj: null,
		name: null,
		email: null,
		phoneNumber: {
			value: null,
			countryCode: null
		},
		password: null,
		confirmPassword: null,
		cep: null,
		address: null,
		address_number: null,
		address_complement: null,
		city: null,
		neighborhood: null,
		state: null,
		termsOfServiceAgreement: false,
		paymentIdentification: {
			email: paymentIdentificationEmail
		}
	})
	const [loadingSignUp, setLoadingSignUp] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const classes = useStyles()
	const history = useHistory()

	/**
	 * In dev env, we create the user with default values if empty
	 * This happens to make signup process easier
	 */
	const defaultDevEmail = "desenvolvimento1@gmail.com"
	const defaultDevPassword = "123456"

	/**
	 * Today we only create app in inbox
	 * In case another Letalk app is created, this must be editable by the user
	 */
	const appCode = "inbox_app"

	const handleSignUpDataChange = (type: string, value: string | boolean | PhoneNumberType) => {
		setSignUpData((currentState) => ({ ...currentState, [type]: value }))

		clearValidation(type)
	}

	const handleSubmitSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoadingSignUp(true)

		try {
			if (isDevEnv) {
				const { data } = await ApiService.post("/auth/signup", {
					name: signUpData.name || "Ambiente de Dev",
					email: signUpData.email || defaultDevEmail,
					cnpj: signUpData.cnpj || "22216929000195",
					phoneNumber: signUpData.phoneNumber.value ? signUpData.phoneNumber : {
						value: "556899921700"
					},
					password: signUpData.password || defaultDevPassword,
					confirmPassword: signUpData.confirmPassword || defaultDevPassword,
					termsOfServiceAgreement: true,
					paymentIdentification: {
						email: signUpData.email || defaultDevEmail
					},
					addressData: {
						cep: signUpData.cep || "18170000",
						address: signUpData.address || "Lugar Muito Aleatorio",
						address_number: signUpData.address_number || "48",
						address_complement: signUpData.address_complement || "Complemento de bairro",
						city: signUpData.city || "Cidade Aleatória",
						neighborhood: signUpData.neighborhood || "Bairro Aleatória",
						state: signUpData.state || "Estado"
					},
					context_url: {
						desiredContext: {
							relatedApplication: appCode
						},
						currentContext: {
							relatedApplication: "account",
							pathUrl: window.location.href
						}
					},
					plan_code: "23"
				})

				if (data) {
					history.push("/signup/user-created", {
						email: signUpData.email || defaultDevEmail
					})
				}
			} else {
				const WebhookCaller = axios.create({
					baseURL: "https://webhook.letalk.com.br",
					withCredentials: true
				})

				const { data } = await ApiService.post("/auth/signup", {
					name: signUpData.name,
					email: signUpData.email,
					cnpj: signUpData.cnpj,
					phoneNumber: signUpData.phoneNumber,
					password: signUpData.password,
					confirmPassword: signUpData.password,
					termsOfServiceAgreement: signUpData.termsOfServiceAgreement,
					paymentIdentification: {
						email: signUpData.paymentIdentification?.email
					},
					addressData: {
						cep: signUpData.cep,
						address: signUpData.address,
						address_number: signUpData.address_number,
						address_complement: signUpData.address_complement,
						city: signUpData.city,
						neighborhood: signUpData.neighborhood,
						state: signUpData.state
					},
					context_url: {
						desiredContext: {
							relatedApplication: appCode
						},
						currentContext: {
							relatedApplication: "account",
							pathUrl: window.location.href
						}
					},
					plan_code: "23"
				})

				await WebhookCaller.post("/81d80093-6c13-4f0b-84f4-8af33f315ea7", {
					contact: {
						name: signUpData.name,
						email: signUpData.email,
						phone: signUpData.phoneNumber.value,
						fields: {
							status_cliente: "Novo Cliente"
						}
					}
				})

				if (data) {
					history.push("/signup/user-created", {
						email: signUpData.email
					})
				}
			}
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoadingSignUp(false)
	}

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const handlePhoneNumberChange = (value: string, country?: CountryData) => {
		handleSignUpDataChange("phoneNumber", {
			value,
			...(country && { countryCode: country.countryCode })
		})
	}

	const maskCnpjOrCpf = (value: string, formType?: string) => {
		let data: string

		if (formType === "pf") {
			data = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
		} else {
			data = value
				.replace(/\D+/g, "")
				.replace(/(\d{2})(\d)/, "$1.$2")
				.replace(/(\d{3})(\d)/, "$1.$2")
				.replace(/(\d{3})(\d)/, "$1/$2")
				.replace(/(\d{4})(\d)/, "$1-$2")
				.replace(/(-\d{2})\d+?$/, "$1")
		}

		return data
	}

	const maskZipCode = (value: string) => {
		if (!value) return ""
		value = value.replace(/\D/g, "")
		value = value.replace(/(\d{5})(\d)/, "$1-$2")
		return value
	}

	return (
		<Container maxWidth={"md"}>
			<Grid container justify="center" className={classes.authContainer}>
				<Grid item xs={12} sm={12} md={12}>
					<Grid container justify="center" className={classes.authContent}>
						<Grid item xs={12} sm={12} md={12} className={classes.authWrapper}>
							<Portlet elevation={1} className={classes.portlet}>
								<img src={logoImg} alt="Letalk" className={classes.logoImg} />

								<Divider size={4} orientation="horizontal" />

								{
									isDevEnv && (
										<Grid>

											<Grid item>
												<Typography>
													<Box fontWeight="fontWeightBold" display="inline">Alerta do ambiente de dev: </Box>
													ao clicar em CADASTRAR com campos vazios, um usuário padrão é criado com email <Box fontWeight="fontWeightBold" display="inline">{ defaultDevEmail } </Box>
													e senha <Box fontWeight="fontWeightBold" display="inline">{ defaultDevPassword }</Box>
												</Typography>
											</Grid>
										</Grid>
									)
								}
								<Grid container component="form" onSubmit={handleSubmitSignUp} spacing={1}>
									<Grid item xs={6}>
										<TextField
											id="input-cnpj"
											name="cnpj"
											value={signUpData.cnpj}
											onChange={
												({ target }) => handleSignUpDataChange(
													"cnpj",
													maskCnpjOrCpf(target.value)
												)
											}
											variant="outlined"
											label={"CNPJ"}
											inputProps={{ maxLength: 18 }}
											fullWidth
											helperText={validation.cnpj}
											error={!!validation.cnpj}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid item xs={6}>
										<TextField
											id="input-name"
											name="name"
											value={signUpData.name}
											onChange={
												({ target }) => handleSignUpDataChange(
													"name",
													onlyLettersNumbersAndAccents(target.value)
												)
											}
											variant="outlined"
											label={"Razão Social"}
											fullWidth
											helperText={validation.name}
											error={!!validation.name}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid
										item
										xs={6}
									>

										<TextField
											id="input-email"
											name="email"
											value={signUpData.email}
											onChange={
												({ target }) => handleSignUpDataChange(
													"email", formatEmail(target.value)
												)
											}
											variant="outlined"
											label="E-mail"
											fullWidth
											helperText={validation.email}
											error={!!validation.email}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid
										hidden={true}
										item
										xs={12}
									>

										<TextField
											disabled
											id="input-payment-identification-email"
											name="payment_identification_email"
											value={signUpData.paymentIdentification?.email}
											variant="outlined"
											label="E-mail da conta de pagamento"
											fullWidth
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid
										item
										xs={6}
									>
										<PhoneInputComponent
											value={signUpData?.phoneNumber?.value}
											specialLabel="Celular"
											country={"br"}
											preferredCountries={["br", "pt", "us"]}
											onChange={(phoneNumber, countryCode) => handlePhoneNumberChange(phoneNumber, countryCode as CountryData)}
											validation={validation.phoneNumber}
										/>

										<Divider orientation="horizontal" size={1} />
									</Grid>

									<Grid item xs={6}>
										<TextField
											id="input-password"
											type={visiblePassword ? "text" : "password"}
											value={signUpData.password}
											onChange={
												({ target }) => handleSignUpDataChange("password", target.value)
											}
											label="Senha"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="alterar visibilidade da senha"
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															edge="end"
														>
															{visiblePassword ? <ShowPassword /> : <HiddenPassword />}
														</IconButton>
													</InputAdornment>
												)
											}}
											fullWidth
											variant="outlined"
											helperText={validation.password}
											error={validation.password}
										/>
									</Grid>

									<Divider orientation="horizontal" size={2} />

									<Grid item xs={12}>
										<Typography variant="h3" >
											Endereço
										</Typography>
									</Grid>

									<Grid
										item
										xs={5}
									>

										<TextField
											id="input-cep"
											name="cep"
											value={signUpData.cep}
											onChange={
												({ target }) => handleSignUpDataChange(
													"cep",
													maskZipCode(target.value)
												)
											}
											inputProps={{ maxLength: 9 }}
											variant="outlined"
											label="CEP"
											fullWidth
											helperText={validation.cep}
											error={!!validation.cep}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid
										item
										xs={5}
									>

										<TextField
											id="input-address"
											name="address"
											value={signUpData.address}
											onChange={
												({ target }) => handleSignUpDataChange(
													"address", target.value
												)
											}
											variant="outlined"
											label="Logradouro"
											fullWidth
											helperText={validation.address}
											error={!!validation.address}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid
										item
										xs={2}
									>

										<TextField
											id="input-address-number"
											name="address_number"
											value={signUpData.address_number}
											onChange={
												({ target }) => handleSignUpDataChange(
													"address_number", target.value
												)
											}
											variant="outlined"
											label="Número"
											fullWidth
											helperText={validation.address_number}
											error={!!validation.address_number}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid
										item
										xs={6}
									>

										<TextField
											id="input-address-complement"
											name="address_complement"
											value={signUpData.address_complement}
											onChange={
												({ target }) => handleSignUpDataChange(
													"address_complement", target.value
												)
											}
											variant="outlined"
											label="Complemento"
											fullWidth
											helperText={validation.address_complement}
											error={!!validation.address_complement}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid
										item
										xs={6}
									>

										<TextField
											id="input-neighborhood"
											name="neighborhood"
											value={signUpData.neighborhood}
											onChange={
												({ target }) => handleSignUpDataChange(
													"neighborhood", target.value
												)
											}
											variant="outlined"
											label="Bairro"
											fullWidth
											helperText={validation.neighborhood}
											error={!!validation.neighborhood}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid
										item
										xs={6}
									>

										<TextField
											id="input-city"
											name="city"
											value={signUpData.city}
											onChange={
												({ target }) => handleSignUpDataChange(
													"city", target.value
												)
											}
											variant="outlined"
											label="Cidade"
											fullWidth
											helperText={validation.city}
											error={!!validation.city}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Grid
										item
										xs={6}
									>

										<TextField
											id="input-state"
											name="state"
											value={signUpData.state}
											onChange={
												({ target }) => handleSignUpDataChange(
													"state", target.value
												)
											}
											variant="outlined"
											label="Estado"
											fullWidth
											helperText={validation.state}
											error={!!validation.state}
											InputLabelProps={{ ...(!isDevEnv && { shrink: true }) }}
										/>

										<Divider orientation="horizontal" size={2} />
									</Grid>

									<Divider orientation="horizontal" size={2} />

									<Grid item xs={12}>
										<FormControl
											error={!!validation.termsOfServiceAgreement}
											component="fieldset"
										>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={signUpData.termsOfServiceAgreement}
															onChange={
																({ target }) => handleSignUpDataChange(
																	"termsOfServiceAgreement", target.checked
																)}
															name="termsOfServiceAgreement"
															color="primary"
														/>}
													label={
														<>
															<Typography>
																Li e aceito os {" "}
																<Link
																	href={letalkLinks.notionTermsOfService}
																	target="_blank"
																	underline="always">
																	Termos de Uso
																</Link>
																{" "} da Letalk
															</Typography>
														</>
													}
												/>
											</FormGroup>
											<FormHelperText id="according-terms-helper-text" variant="outlined">
												{validation.termsOfServiceAgreement}
											</FormHelperText>
										</FormControl>
									</Grid>

									<Divider orientation="horizontal" size={2} />

									<Grid item xs={12}>
										<Button
											color="primary"
											variant="contained"
											type='submit'
											disabled={loadingSignUp}
											endIcon={loadingSignUp && (
												<CircularProgress size={20} color="inherit" />
											)}
											fullWidth
											className={classes.submitButton}
										>
											Cadastrar
										</Button>
									</Grid>
								</Grid>

								<Divider orientation="horizontal" size={1.5} />
								<Typography align="center">
									Já possui cadastro?{" "}
									<Link
										href={inboxAppUrl.cpURL}
										underline="always">
										Acessar
									</Link>
								</Typography>
							</Portlet>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

export default SignUp
