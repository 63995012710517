import React, { useState } from "react"
import { Switch, Route, useHistory } from "react-router-dom"

import PrivateRoutes from "@/routes/private"
import PublicRoutes from "@/routes/public"
import DevelopmentRoutes from "@/routes/development"

import useDidMount from "@/hooks/useDidMount"

import ErrorHandlerService from "@/services/ErrorHandler"
import AuthService from "@/services/Auth"

import { useGlobalStateStore } from "@/store/GlobalState"

import { Loading } from "@/components"
import AccountService from "@/services/Account"
import { inboxAppUrl } from "@/config/url"
import { initGoogleTagManager, triggerGoogleAnalyticsPageView, addDataLayerVariables } from "@/services/Google"

const PrivateComponent = () => {
	const [loading, setLoading] = useState(true)

	const history = useHistory()
	const globalStateStore = useGlobalStateStore()

	const getInitialData = async () => {
		const isLoggedIn = await AuthService
			.isLoggedIn()

		if (isLoggedIn) {
			try {
				const account = await AccountService.getInfo()

				if (account) {
					globalStateStore.setAccountData({
						id: account.id,
						name: account.name,
						email: account.email,
						extra_data: account.extra_data
					})

					addDataLayerVariables({
						is_admin: Boolean(account?.extra_data?.is_admin_mode),
						is_tester: Boolean(account?.extra_data?.is_tester)
					})
				}
			} catch (error) {
				ErrorHandlerService.handle(error)
			}
		} else {
			const prevUrlIsFromInbox = document?.referrer?.includes(inboxAppUrl.cpURL as string)

			history.push(`/signin?${prevUrlIsFromInbox ? "noRedirect=true" : ""}`, { prevUrl: window.location.href })
		}

		setLoading(false)
	}

	useDidMount(() => {
		getInitialData()
	})

	return (
		<Loading loading={loading} >

			<PrivateRoutes />

		</Loading>
	)
}

const Routes = () => {
	const history = useHistory()

	history.listen(({ pathname }) => {
		triggerGoogleAnalyticsPageView(pathname)
	})

	useDidMount(() => {
		initGoogleTagManager()
	})
	return (
		<Switch>
			{PublicRoutes}
			{DevelopmentRoutes}
			<Route path="/" component={PrivateComponent} />
			<Route path="*" component={() => <h1>Page Not Found</h1>} />
		</Switch>
	)
}

export default Routes
