import React, { useState } from "react"
import useDidMount from "@/hooks/useDidMount"
import { Button, Grid } from "@material-ui/core"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import { Plan } from "@/protocols/plan"
import { Link } from "react-router-dom"

const appId = 2
const InboxPlans = () => {
	const [plans, setPlans] = useState([] as Plan[])

	const getAllPlans = async () => {
		try {
			const response = await ApiService.get(`plans/app/${appId}`)
			const plans = response.data?.plans as Plan[]
			return plans
		} catch (err) {
			ErrorHandler.handle(err)
			return []
		}
	}

	const updatePlans = async () => {
		const plansList = await getAllPlans()
		setPlans(plansList)
	}

	useDidMount(() => {
		updatePlans()
	})

	return (
		<Grid container>
			{plans.map(plan => (
				<Grid item key={plan.id}>
					<Button
						component={Link}
						to={{
							pathname: "/checkout/overview",
							search: `app_id=${plan.app_id}&plan_id=${plan.id}&periodicity_in_months=1`
						}}
					>{plan.display_name}</Button>
				</Grid>
			))}
		</Grid>
	)
}

export default InboxPlans
