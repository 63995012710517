import React from "react"
import { Redirect, Route } from "react-router-dom"

import SignIn from "@/pages/Auth/SignIn"

import SignUp from "@/pages/Auth/SignUp"

import RequestRecoveryPassword from "@/pages/Auth/RecoveryPassword/Request"
import ResetRecoveryPassword from "@/pages/Auth/RecoveryPassword/Reset"

import UserRedirect from "@/pages/Auth/UserRedirect"
import { inboxAppUrl } from "@/config/url"
import TrialSignUp from "@/pages/Auth/TrialSignUp"
import UserCreatedPage from "@/pages/Auth/SignUp/UserCreatedPage"
import WabaSignUp from "@/pages/Auth/WabaSignUp"
import { isDevEnv } from "@/utils/environment"

const signupRoute = isDevEnv ? "signup" : "signup-5jg4l"

const PublicRoutes = [
	<Route
		key="home"
		exact
		path="/"
		render={() => <Redirect to="user-redirect" />}
	/>,
	<Route
		exact
		key="user-redirect"
		path="/user-redirect"
		component={UserRedirect}
	/>,
	<Route
		exact
		key="signup"
		path={`/${signupRoute}`}
		component={SignUp}
	/>,
	<Route
		exact
		key="signup"
		path="/signup-waba"
		component={WabaSignUp}
	/>,
	<Route
		exact
		key="signup"
		path={`/${signupRoute}-trial-7d`}
		component={TrialSignUp}
	/>,
	<Route
		exact
		key="signin"
		path="/signin"
		render={({ location }) => {
			if (location.search.includes("noRedirect=true")) {
				return <SignIn />
			} else {
				window.location.assign(`${inboxAppUrl.cpURL}/signin`)
			}
		}}
	/>,
	<Route
		exact
		key="recovery-request"
		path="/recovery/request"
		component={RequestRecoveryPassword}
	/>,
	<Route
		exact
		key="recovery-reset"
		path="/recovery/reset"
		component={ResetRecoveryPassword}
	/>,
	<Route
		exact
		key="user-created"
		path="/signup/user-created"
		component={UserCreatedPage}
	/>

]

export default PublicRoutes
