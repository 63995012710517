import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	authContainer: {
		height: "100%"
	},
	authContent: {
		height: "100%"
	},
	authWrapper: {
		margin: "auto 0"
	},
	portlet: {
		padding: `${theme.spacing(6)}px 10%`
	},
	logoImg: {
		display: "flex",
		margin: "0 auto",
		height: 50
	},
	tabs: {
		backgroundColor: colors.greyScale[11],
		color: colors.greyScale[3],
		borderRadius: "6px 6px 0px 0px"
	},
	tab: {
		fontSize: "14px",
		fontWeight: "normal"
	},
	submitButton: {}
}))

export default useStyles
