export const handleBackButton = (onBackButtonPress: () => void) => {
	const lastPathname = window.location.pathname
	const lastSearch = window.location.search

	/**
	 * Creates a new history without changing any page data.
	 */
	window.history.pushState("forward", "", `${lastPathname}${lastSearch}`)

	/**
	 * if it goes to the last pathname we trigger the callback function
	 * since it means the user has gone back with back button.
	 */
	window.addEventListener("popstate", function onPopStateChange () {
		if (lastPathname === window.location.pathname) {
			onBackButtonPress()
		}

		// eslint-disable-next-line
		window.removeEventListener("popstate", onPopStateChange)
	})
}
