import React from "react"
import { Grid, Typography, CircularProgress } from "@material-ui/core"
import { Divider } from "@/components"

const CreditCard = () => {
	return (
		<Grid container alignItems="center" direction="column" spacing={4}>
			<Grid item>
				<CircularProgress size={128} thickness={2.7} />
			</Grid>

			<Divider orientation="horizontal" size={5} />

			<Grid item >
				<Typography variant="h2" align="center">
					Estamos processando o seu pagamento...
				</Typography>
			</Grid>

			<Grid item >
				<Typography variant="body1" align="center">
					Imediatamente após a aprovação do pagamento, a sua assinatura será ativada!
				</Typography>
			</Grid>
		</Grid>
	)
}

export default CreditCard
