import { CodeMessage } from "@/protocols/messages"
import CodeMessages from "@/utils/responseMessages/codeMessages"

const Messages = (code_message: CodeMessage): string => {
	const messageResponse = CodeMessages[code_message] ||
		"Algo deu errado e não conseguimos identificar. Entre em contato."

	return messageResponse
}

export default Messages
