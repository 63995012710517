import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
	Avatar,
	Container,
	Grid,
	Menu,
	MenuItem,
	Typography,
	ListItemText,
	ListItemIcon,
	useMediaQuery,
	useTheme
} from "@material-ui/core"
import { ExitToApp as LogoutIcon, AccountCircleOutlined as ProfileIcon } from "@material-ui/icons"

import AuthService from "@/services/Auth"

import { Divider } from "@/components"

import useStyles from "@/components/Header/styles"

import { useGlobalStateStore } from "@/store/GlobalState"

import logoImg from "@/assets/images/logos/letalk-logo-white.svg"

interface MenuTitleOptions {
	[key: string]: string
}

const Header: React.FC = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const MuiTheme = useTheme()
	const classes = useStyles()
	const globalStateStore = useGlobalStateStore()

	const accountStore = globalStateStore.account

	const openMenu = Boolean(anchorEl)

	const { pathname } = window.location
	const menuTitleOptions: MenuTitleOptions = {
		"/checkout": "Checkout",
		"/profile": "Minha conta",
		default: ""
	}
	const menuTitle = menuTitleOptions[pathname] ?? menuTitleOptions.default

	const isSmall = useMediaQuery(MuiTheme.breakpoints.down("xs"))

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleLogout = async (event: React.SyntheticEvent) => {
		event.preventDefault()

		await AuthService.logout()
	}

	return (
		<Grid
			container
			alignItems="center"
			justify="flex-end"
			className={classes.container}
		>
			<Container maxWidth={"xl"}>
				<Grid container justify="space-between" alignContent="center" alignItems="center">
					<Grid item>
						<Grid container alignItems="center">
							<Grid item>
								<Link
									className={classes.logo}
									to={"/wizard-app"}
								>
									<img
										src={logoImg}
										alt="Letalk"
										className={classes.logoImg}
									/>
								</Link>
							</Grid>
							<Divider size={2} orientation={"vertical"} />

							<Grid item className={classes.textWhite}>
								<Typography
									color="inherit"
									className={classes.textTitle}>
									{menuTitle}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid item>
						<Grid
							container
							wrap="nowrap"
						>
							{
								!isSmall &&
								<>
									<Grid
										container
										direction="column"
										alignItems="flex-end"
										className={classes.textWhite}
									>
										<Typography
											color="inherit"
											className={classes.textName}
										>
											{accountStore.name}
										</Typography>

										<Typography
											color="inherit"
											variant="caption"
										>
											{accountStore.email}
										</Typography>
									</Grid>

									<Divider orientation="vertical" size={3} />
								</>
							}

							<Avatar
								aria-label="more"
								aria-controls="header-menu"
								aria-haspopup="true"
								onClick={handleClickMenu}
								className={classes.profile}
							>
								{
									accountStore
										?.name
										.charAt(0)
										.toUpperCase()
								}
							</Avatar>

							<Menu
								id="header-menu"
								anchorEl={anchorEl}
								keepMounted
								open={openMenu}
								onClose={handleCloseMenu}
								autoFocus={false}
								PaperProps={{
									style: {
										marginTop: 40
									}
								}}
							>
								<MenuItem
									component={Link}
									to="/profile"
								>
									<ListItemIcon>
										<ProfileIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Minha conta" />
								</MenuItem>

								<MenuItem
									component="a"
									href="#logout"
									onClick={handleLogout}
								>
									<ListItemIcon>
										<LogoutIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Sair" />
								</MenuItem>
							</Menu>
						</Grid>

					</Grid>
				</Grid>

			</Container>
		</Grid>
	)
}

export default Header
