export default {
	invalid_expiration_date: "Data inválida",
	expired_card: "Cartão expirado",
	cpf_or_cnpj_invalid: "CPF ou CNPJ inválido",
	credit_card_number_invalid: "Número inválido",
	zip_code_invalid: "CEP inválido",
	verification_code_does_not_match_card_brand: "Código de segurança inválido",
	last_name_cannot_be_empty: "É necessário pelo menos um sobrenome",

	coupon_does_not_exist: "Esse cupom não existe",
	coupon_does_not_apply_to_plan: "Esse cupom não se aplica ao plano selecionado",
	coupon_only_applies_to_1_month_periodicity: "Esse cupom se aplica apenas ao plano mensal",
	coupon_only_applies_to_12_month_periodicity: "Esse cupom se aplica apenas ao plano anual",
	coupon_does_not_apply_to_installment_count: "Esse cupom não se aplica ao parcelamento selecionado",
	coupon_expired: "Esse cupom expirou"
}
