import React, { useState, cloneElement } from "react"
import {
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	CircularProgress,
	Button,
	Grid,
	IconButton,
	Drawer,
	Typography
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

import useStyles from "@/components/InputDialog/styles"

import { handleBackButton } from "@/utils/backButton"

type InitialValueType = string | number | boolean

type InputDialogProps = {
	children?: React.ReactNode
	customInputElement: React.ReactNode
	title: string
	onOk: (props: InitialValueType) => Promise<boolean>
	initialValue: InitialValueType

	onCancel?: () => {}
	onOpen?: (() => Promise<void>) | (() => void)
	onDataChange?: () => void
	description?: string
	customAdditionalElement?: React.ReactNode
	parser?: (props: InitialValueType) => InitialValueType
	dataChangeEvent?: "onChange" | "onBlur"
	openOnDrawer?: boolean
	fullWidth?: boolean
}

const InputDialog = (props: InputDialogProps) => {
	const {
		children,
		title,
		onOk,
		onCancel,
		onOpen,
		customInputElement,
		customAdditionalElement,
		initialValue,
		onDataChange,
		fullWidth,
		openOnDrawer,
		parser,
		dataChangeEvent,
		description
	} = props

	const [loading, setLoading] = useState(false)
	const [opened, setOpened] = useState(false)
	const [inputData, setInputData] = useState<InitialValueType>(initialValue)

	const classes = useStyles({})

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const input: any = customInputElement || children

	const handleClose = async () => {
		setOpened(false)

		onCancel && onCancel()
	}

	const handleOk = async () => {
		setLoading(true)

		const result = await onOk(inputData)

		if (result === true) {
			handleClose()
		}

		setLoading(false)
	}

	const handleOpen = async () => {
		onOpen && await onOpen()

		handleBackButton(handleClose)

		setInputData(initialValue)
		setOpened(true)
	}

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		let data = event.target.value as InitialValueType

		if (parser) {
			data = parser(data)
		}

		setInputData(data)

		onDataChange && onDataChange()
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		handleOk()
	}

	const content = (
		<form onSubmit={handleSubmit}>
			{!openOnDrawer && (
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>
			)}
			<DialogTitle disableTypography>
				<Typography variant="h3">
					{title}
				</Typography>
			</DialogTitle>

			<DialogContent>
				{
					description &&
					(
						<DialogContentText>
							{description}
						</DialogContentText>
					)
				}
				{
					cloneElement(input, {
						[dataChangeEvent || "onChange"]: handleChange,
						value: inputData,
						autoFocus: true
					})
				}
				{
					customAdditionalElement && customAdditionalElement
				}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
				>
					Cancelar
				</Button>
				<Button
					endIcon={
						loading && <CircularProgress size={20} className={classes.loading} />}
					onClick={handleOk}
					color="primary"
					variant="contained"
					type="submit"
				>
					Salvar
				</Button>
			</DialogActions>
		</form>
	)

	return (
		<>
			<Grid
				item
				onClick={handleOpen}
				className={classes.children}
			>
				{children}
			</Grid>
			{openOnDrawer ? (
				<Drawer
					anchor="right"
					open={opened}
					onClose={handleClose}
					classes={{ paper: classes.drawerPaper }}
					style={{ width: "100vw" }}
				>
					{content}
				</Drawer>
			)
				: (
					<Dialog
						open={opened}
						onClose={handleClose}
						className={classes.dialog}
						fullWidth={fullWidth}
					>
						{content}
					</Dialog>
				)}
		</>
	)
}

export default InputDialog
