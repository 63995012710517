import { createMuiTheme } from "@material-ui/core"

import colors from "@/styles/colors"

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
	palette: {
		background: {
			default: colors.palette.background,
			paper: colors.greyScale[11]
		},
		primary: {
			main: colors.palette.primary
		},
		secondary: {
			main: colors.palette.confirm,
			contrastText: "#FFF"
		},
		info: {
			main: colors.palette.tag2
		},
		warning: {
			main: colors.palette.tag3
		},
		success: {
			main: colors.palette.confirm
		},
		text: {
			primary: colors.greyScale[2]
		}
	},
	overrides: {
		MuiLink: {
			root: {
				color: colors.palette.link
			}
		},
		MuiFab: {
			root: {
				boxShadow: "0px 2px 4px rgba(33, 36, 41, 0.08)",
				backgroundColor: colors.greyScale[11]
			}
		},
		MuiInputLabel: {
			root: {
				fontFamily: "IBM Plex Sans",
				fontStyle: "normal",
				fontWeight: "normal",
				fontSize: "0.9rem",
				lineHeight: "18px",
				textTransform: "none",
				color: colors.greyScale[3]
			}
		},
		MuiButton: {
			root: {
				padding: 16
			}
		}
	},
	typography: {
		h1: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "2rem",
			lineHeight: "125%",
			color: colors.greyScale[1]
		},
		h2: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "1.5rem",
			lineHeight: "125%",
			color: colors.greyScale[2]
		},
		h3: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "1.125rem",
			lineHeight: "125%",
			color: colors.greyScale[3]
		},
		h4: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "1rem",
			lineHeight: "125%",
			color: colors.greyScale[2]
		},
		h5: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "0.8rem",
			lineHeight: "125%",
			color: colors.greyScale[3]
		},
		h6: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "bold",
			fontSize: "0.7rem",
			lineHeight: "125%",
			color: colors.greyScale[1]
		},
		body1: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "1rem",
			lineHeight: "125%",
			color: colors.greyScale[3]
		},
		overline: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "0.9rem",
			lineHeight: "125%",
			color: colors.greyScale[3],
			textTransform: "none"
		},
		caption: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "0.8rem",
			lineHeight: "125%",
			color: colors.greyScale[3]
		},
		button: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "1rem",
			lineHeight: "125%"
		}
	},
	shadows: Object.values({
		...defaultTheme.shadows,
		0: "0px 0px 0px rgba(0, 0, 0, 0)",
		1: "0px 2px 4px rgba(33, 36, 41, 0.08)"
		// eslint-disable-next-line
	}) as any
})

export default theme
