import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { Dialog, Grid, Typography } from "@material-ui/core"

import { Loading, Divider } from "@/components"

import useStyles from "@/components/RedirectApp/styles"
import ApiService from "@/services/Api"
import useValidation from "@/hooks/useValidation"

export type RedirectAppProps = {
	openDialog: boolean,
	redirectData: {
		context_url: {
			desiredContext: {
				relatedApplication: string
			},
			currentContext: {
				relatedApplication: string,
				pathUrl: string
			}
		}}
}

const RedirectApp: React.FC<RedirectAppProps> = (props) => {
	const {
		openDialog,
		redirectData
	} = props

	const classes = useStyles()
	const history = useHistory()

	const {
		triggerValidation
	} = useValidation()

	useEffect(() => {
		const redirectUser = async () => {
			if (openDialog) {
				// Timeout exists only for visual loading effect,
				// not required for operation
				setTimeout(async () => {
					try {
						const { data } =	await ApiService.post("/redirect/app", redirectData)

						if (data?.pathToRedirect) {
							window.location.href = data.pathToRedirect
						} else {
							history.push("/wizard-app")
						}
					} catch (error) {
						triggerValidation(error)
					}
				}, 2000)
			}
		}

		redirectUser()
	}, [openDialog, triggerValidation, redirectData, history])

	return (
		<Dialog
			fullScreen
			open={Boolean(openDialog)}
		>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.container}
			>
				<Grid item>
					<Loading loading />
				</Grid>

				<Divider size={4} orientation="horizontal"/>

				<Grid item>
					<Typography>Aguarde! Estamos configurando o aplicativo...</Typography>
				</Grid>
			</Grid>
		</Dialog>
	)
}

export default RedirectApp
