import React, { useState } from "react"

import { Container, Grid, Typography } from "@material-ui/core"

import { Divider, Loading } from "@/components"
import RedirectApp, { RedirectAppProps } from "@/components/RedirectApp"
import AppCard from "@/pages/WizardApp/Components/AppCard"

import { AppName } from "@/protocols/app"

import appSiteIllustrationImg from "@/assets/images/illustrations/appSiteIllustration.svg"
import useDidMount from "@/hooks/useDidMount"
import ApiService from "@/services/Api"
import useValidation from "@/hooks/useValidation"
import AppCardSkeleton from "@/skeletons/WizardApp/AppCardSkeletons"

interface AppOptions {
	[key: string]: {
		image: string,
		description: string
	};
}

type AppType = {
	id: number
	name: string
	code: string
}

const appOptions: AppOptions = {
	mini_site_app: {
		image: appSiteIllustrationImg,
		description: "Tenha um site personalizado para vender o que quiser."
	},
	inbox_app: {
		image: appSiteIllustrationImg,
		description: "Tenha um site personalizado para vender o que quiser."
	},
	widgets_app: {
		image: appSiteIllustrationImg,
		description: "Tenha um site personalizado para vender o que quiser."
	}
}

const appRedirectInitial = {
	openDialog: false,
	redirectData: {}
}

const WizardApp = () => {
	const [appLoading, setAppLoading] = useState<boolean>(true)
	const [apps, setApps] = useState<AppType[]>([] as AppType[])
	const [appRedirect, setAppRedirect] = useState<RedirectAppProps>(appRedirectInitial as RedirectAppProps)

	const {
		triggerValidation
	} = useValidation()

	const onSelectApp = async (appCode: string) => {
		setAppRedirect({
			openDialog: true,
			redirectData: {
				context_url: {
					desiredContext: {
						relatedApplication: appCode
					},
					currentContext: {
						relatedApplication: "account",
						pathUrl: window.location.href
					}
				}
			}
		})
	}

	const getApps = async () => {
		setAppLoading(true)

		try {
			const { data } = await ApiService.get("/apps")

			setApps(data.apps)
		} catch (error) {
			triggerValidation(error)
		}

		setAppLoading(false)
	}

	useDidMount(() => {
		getApps()
	})

	return (

		<Container maxWidth="md">
			<Typography variant="h1" align="center">Por onde quer começar?</Typography>

			<Divider size={4} orientation="horizontal"/>

			<Container maxWidth="sm">

				<Loading
					loading={appLoading}
					customLoadingElement={appLoading && <AppCardSkeleton/>}
				>
					<Grid
						container
						justify="flex-start"
						alignContent="center"
						alignItems="center"
						spacing={1}>
						{
							!!apps.length &&
								apps.map((app, index) => (
									<Grid key={index} item xs={12} sm={6}>
										<Grid container justify="center">
											<Grid item>
												<AppCard
													image={appOptions[app.code]?.image}
													appName={app.name as AppName}
													description={appOptions[app.code]?.description}
													onSelect={() => onSelectApp(app.code)}
												/>
											</Grid>
										</Grid>
									</Grid>
								))
						}
					</Grid>
				</Loading>

			</Container>

			<RedirectApp
				openDialog={appRedirect.openDialog}
				redirectData={appRedirect.redirectData}
			/>
		</Container>
	)
}

export default WizardApp
