import { Grid, Box, Typography, Container } from "@material-ui/core"
import { Divider } from "@/components"
import React from "react"
import colors from "@/styles/colors"

const Palette = () => {
	return (
		<Container maxWidth="md">
			<Grid container alignItems="flex-start" style={{ backgroundColor: "#ffffff" }}>
				<Divider orientation="horizontal" size={2} />

				<Typography variant="h3">
					Palette
				</Typography>

				<Divider orientation="horizontal" size={2} />

				{Object.entries(colors.palette).map(([key, color]) => {
					return (
						<Box
							key={key}
							height="150px"
							width="150px"
							bgcolor={color}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							{key}
						</Box>
					)
				})}

				<Divider orientation="horizontal" size={2} />

				<Typography variant="h3">
					GreyScale
				</Typography>

				<Divider orientation="horizontal" size={4} />

				{Object.entries(colors.greyScale).map(([key, color]) => {
					return (
						<Box
							key={key}
							height="150px"
							width="150px"
							bgcolor={color}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							{key}
						</Box>
					)
				})}
			</Grid >
		</Container>
	)
}

export default Palette
