import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

type UseStylesProps = {
	padding?: string
	width?: string
}

const useStyles = makeStyles((theme) => {
	return {
		badge: {
			height: "100%",
			width: "100%",
			borderRadius: theme.shape.borderRadius,
			border: "1px solid rgba(0,0,0,0.23)" // same as TextField
		},
		badgeIsActive: {
			borderColor: "transparent"
		},
		badgeAvatar: {
			backgroundColor: colors.palette.confirm,
			height: theme.spacing(3.5),
			width: theme.spacing(3.5)
		},
		card: {
			width: "100%",
			padding: (props: UseStylesProps) => props.padding || theme.spacing(6),
			border: `${theme.spacing(0.5)}px solid transparent`,
			borderRadius: theme.shape.borderRadius
		},
		cardIsActive: {
			borderColor: colors.palette.confirm
		},
		cardIsDisabled: {
			opacity: 0.3
		},
		gridItem: {
			width: (props: UseStylesProps) => props.width
		}
	}
})

export default useStyles
