import React from "react"
import { Grid, Typography, Container, Button } from "@material-ui/core"
import { Portlet, Divider } from "@/components"
import { Done as CheckCircleIcon } from "@material-ui/icons"
import CheckoutStepper from "@/pages/Checkout/Stepper"
import useStyles from "@/pages/Checkout/SubscriptionActivated/styles"
import { Link } from "react-router-dom"

const SubscriptionActivated = () => {
	const classes = useStyles()

	return (
		<Container maxWidth="md" disableGutters>
			<Divider orientation="horizontal" size={4} />

			<CheckoutStepper activeStep={2} />

			<Divider orientation="horizontal" size={4} />

			<Portlet className={classes.portlet}>
				<Grid container alignItems="center" direction="column" spacing={2}>
					<Grid item>
						<CheckCircleIcon color="secondary" className={classes.checkCircleIcon} />
					</Grid>

					<Grid item >
						<Typography variant="h2" color="secondary" align="center">
							Pagamento aprovado e assinatura ativada!
						</Typography>
					</Grid>

					<Divider orientation="horizontal" size={3} />

					<Grid item >
						<Typography variant="h5" align="center">
							Estamos imensamente felizes por fazer parte da comunidade Letalk!
						</Typography>
					</Grid>

					<Grid item >
						<Typography variant="body1" align="center">
							Temos a certeza de que iremos superar as suas expectativas! {" "}
							<span role="img" aria-label="smile">
								😊
							</span>
						</Typography>
					</Grid>

					<Divider orientation="horizontal" size={4} />

					<Grid item>
						<Button
							variant="contained"
							color="primary"
							component={Link}
							to={"/profile"}
						>
							Voltar ao painel
						</Button>
					</Grid>
				</Grid>
			</Portlet>

			<Divider orientation="horizontal" size={6} />
		</Container >
	)
}

export default SubscriptionActivated
