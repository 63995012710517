import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(() => ({
	circularProgress: {
		color: colors.greyScale[11]
	}
}))

export default useStyles
