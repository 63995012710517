import React, { useEffect, useRef, useState } from "react"
import { Grid, Typography, Button, Divider as MuiDivider, TextField, CircularProgress } from "@material-ui/core"
import { Plan } from "@/protocols/plan"
import { transformCentsInDefaultPattern } from "@/utils/money"
import { Portlet, Divider, InputDialog } from "@/components"
import { Coupon } from "@/protocols/coupon"
import ApiService from "@/services/Api"
import { isSmallScreen } from "@/utils/checkDevice"
import useValidation from "@/hooks/useValidation"
import colors from "@/styles/colors"
import { Cancel as CancelIcon } from "@material-ui/icons"

type CouponAndOverviewProps = {
	selectedCoupon?: Coupon | null
	handleChangeSelectedCoupon: (coupon: Coupon | null) => void
	selectedInstallmentCount: number
	selectedPlan?: Plan
	selectedPeriodicityInMonths: number
}

const CouponAndOverview = ({
	selectedCoupon,
	handleChangeSelectedCoupon,
	selectedInstallmentCount,
	selectedPlan,
	selectedPeriodicityInMonths
}: CouponAndOverviewProps) => {
	const [loadingCoupon, setLoadingCoupon] = useState(false)
	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const getCoupon = async (couponName: string) => {
		try {
			const { data } = await ApiService.get(
				`coupon/${couponName}`
			)
			return data as Coupon
		} catch (err) {
			triggerValidation(err)
			return null
		}
	}

	const removeCoupon = () => {
		handleChangeSelectedCoupon(null)
	}

	const handleChangeCoupon = async (couponName: string | number | boolean) => {
		clearValidation("coupon")

		const coupon = await getCoupon(couponName as string)
		if (coupon) {
			handleChangeSelectedCoupon(coupon)
			return true
		}

		return false
	}

	const handleClearCouponNameValidation = () => {
		clearValidation("coupon_name")
	}

	const getDefaultPlanPriceInCents = () => {
		return (selectedPlan?.price_in_cents || 0) * selectedPeriodicityInMonths
	}

	const getCouponDescription = () => {
		let description = ""
		let subDescription = ""

		const discount = selectedCoupon?.discount_value_type === "amount_in_cents"
			? `R$ ${transformCentsInDefaultPattern(selectedCoupon?.discount_value as number)}`
			: `${selectedCoupon?.discount_value}%`

		const periodicityName = selectedPeriodicityInMonths === 1 ? "mensalidade" : "anuidade"

		const periodicityIntervalName = selectedPeriodicityInMonths === 1 ? "mês" : "ano"

		const defaultPrice = transformCentsInDefaultPattern(getDefaultPlanPriceInCents())

		if (selectedCoupon?.discount_type === "first_recurrence") {
			description = `Cupom de ${discount} de desconto na 1º ${periodicityName}`
			subDescription = `* A partir do 2º ${periodicityIntervalName} o valor é R$ ${defaultPrice} por ${periodicityIntervalName} `
		} else {
			description = `Cupom de ${discount} de desconto em todas as ${periodicityName}s`
		}

		return (
			<>
				<Typography variant="body2">
					{description}
				</Typography>

				{subDescription && (
					<Typography variant="caption">
						{subDescription}
					</Typography>
				)}
			</>
		)
	}

	const getTotalDiscountInCents = () => {
		const couponDiscountValue = selectedCoupon?.discount_value as number
		const planPricePerMonth = selectedPlan?.price_in_cents as number

		const totalDiscountInCents = selectedCoupon?.discount_value_type === "amount_in_cents"
			? couponDiscountValue
			: (couponDiscountValue / 100) * planPricePerMonth * selectedPeriodicityInMonths

		return Math.round(totalDiscountInCents) || 0
	}

	const getOverviewDiscountLine = () => {
		const periodicityName = selectedPeriodicityInMonths === 1 ? "mensalidade" : "anuidade"

		const totalDiscount = transformCentsInDefaultPattern(getTotalDiscountInCents())

		return (
			<>
				<Typography variant="body1">
					Desconto na {selectedCoupon?.discount_type === "first_recurrence" && "1º "}{periodicityName}
				</Typography>

				<Typography variant="body1" color="error">
					- R$ {totalDiscount}
				</Typography>
			</>
		)
	}

	const getFinalPriceInCents = () => {
		const isCouponValid = !validation.coupon
		const totalDiscountInCents = isCouponValid ? getTotalDiscountInCents() : 0
		const defaultPlanPriceInCents = getDefaultPlanPriceInCents()
		const finalPrice = defaultPlanPriceInCents - totalDiscountInCents

		if (finalPrice < 0) {
			return 0
		}

		return finalPrice
	}

	const getOverviewTotalLine = () => {
		const totalPriceInCents = getFinalPriceInCents()

		const installmentPriceInCents = Math.round(totalPriceInCents / selectedInstallmentCount)

		return (
			<>
				<Typography variant="body1">
					Total
				</Typography>

				{selectedInstallmentCount === 1
					? (
						<Typography variant="body1" style={{ fontWeight: "bolder" }}>
							R$ {transformCentsInDefaultPattern(installmentPriceInCents)}
						</Typography>
					) : (
						<Grid item>
							<Grid container direction="column" alignItems="flex-end">
								<Typography variant="body1" style={{ fontWeight: "bolder" }}>
									{selectedInstallmentCount}x de R$ {transformCentsInDefaultPattern(installmentPriceInCents)}
								</Typography>

								<Typography variant="caption">
									total R$ {transformCentsInDefaultPattern(totalPriceInCents)}
								</Typography>
							</Grid>
						</Grid>

					)}
			</>
		)
	}

	// using ref to include on useEffect dependencies
	// eslint-disable-next-line
	const validateCoupon = useRef<Function>(() => { })

	validateCoupon.current = async () => {
		setLoadingCoupon(true)
		clearValidation("coupon")

		try {
			await ApiService.post(
				"coupon/validate",
				{
					plan_id: selectedPlan?.id,
					periodicity_in_months: selectedPeriodicityInMonths,
					installment_count: selectedInstallmentCount,
					coupon_id: selectedCoupon?.id
				}
			)
		} catch (err) {
			if (err?.response?.data?.codeMessages?.coupon === "coupon_does_not_apply_to_periodicity") {
				err.response.data.codeMessages.coupon = selectedPeriodicityInMonths === 1
					? "coupon_only_applies_to_12_month_periodicity"
					: "coupon_only_applies_to_1_month_periodicity"
			}
			triggerValidation(err)
		}

		setLoadingCoupon(false)
	}

	useEffect(() => {
		if (selectedCoupon && selectedPlan) {
			validateCoupon.current()
		}
	}, [selectedCoupon, selectedInstallmentCount, selectedPlan, selectedPeriodicityInMonths, validateCoupon])

	return (
		<Portlet elevation={1} >
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h3" >
						Cupom de desconto
					</Typography>
				</Grid>

				<Grid item md={8} xs={12}>
					{
						selectedCoupon ? (
							loadingCoupon ? (
								<Button
									disableElevation
									variant="contained"
									disabled
									endIcon={<CircularProgress size={20} />}
								>
									{selectedCoupon?.name}
								</Button>
							) : (
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Button
											disableElevation
											variant="contained"
											onClick={removeCoupon}
											style={{
												backgroundColor: validation.coupon ? colors.palette.error : colors.palette.confirm,
												color: colors.greyScale[11]
											}}
											endIcon={<CancelIcon style={{ marginLeft: 16 }} />}
										>
											{selectedCoupon?.name}
										</Button>
									</Grid>

									<Grid item xs={12}>
										{validation.coupon
											? (
												<Typography color="error" variant="body2">
													{ validation.coupon}
												</Typography>
											)
											: getCouponDescription()
										}
									</Grid>
								</Grid>
							)) : (
							<InputDialog
								title="Cupom"
								onOk={handleChangeCoupon}
								initialValue={""}
								customInputElement={(
									<TextField
										error={!!validation.coupon_name}
										helperText={validation.coupon_name}
										variant="standard"
										color="secondary"
										fullWidth
									/>
								)}
								openOnDrawer={isSmallScreen}
								onOpen={handleClearCouponNameValidation}
								onDataChange={handleClearCouponNameValidation}
								fullWidth
							>
								<Button
									variant="contained"
									color="secondary"
								>
									Adicionar cupom
								</Button>
							</InputDialog>
						)
					}
				</Grid>

				<Divider orientation="horizontal" size={3} />

				<Grid item md={8} xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h3" >
								Resumo
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<MuiDivider />
						</Grid>

						<Divider orientation="horizontal" size={1} />

						<Grid item xs={12}>
							<Grid container justify="space-between">
								<Typography variant="body1">
									{selectedPeriodicityInMonths === 1 ? "Valor da mensalidade" : "Valor do anual"}
								</Typography>

								<Typography variant="body1">
									R$ {transformCentsInDefaultPattern((selectedPlan?.price_in_cents || 0) * selectedPeriodicityInMonths)}
								</Typography>
							</Grid>
						</Grid>

						{
							(selectedCoupon && !validation.coupon) && (
								<>
									<Grid item xs={12}>
										<Grid container justify="space-between">
											{getOverviewDiscountLine()}
										</Grid>
									</Grid>
								</>
							)
						}

						<Divider orientation="horizontal" size={1} />

						<Grid item xs={12}>
							<MuiDivider />
						</Grid>

						<Grid item xs={12}>
							<Grid container justify="space-between">
								{getOverviewTotalLine()}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default CouponAndOverview
