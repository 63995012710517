import React from "react"
import { Grid, Typography, TextField, InputLabel, InputAdornment, useTheme, useMediaQuery } from "@material-ui/core"
import { Lock as LockIcon } from "@material-ui/icons"
import { formatCreditCardExpirationDate, formatCreditCardVerificationValue, formatZipCode, formatCreditCardNumber, formatInteger, formatCpfOrCnpj } from "@/utils/mask"
import { PaymentMethod } from "@/protocols/payment"
import { Portlet, Divider, CardSelect } from "@/components"
import colors from "@/styles/colors"
import useStyles from "@/pages/Checkout/SelectPlanAndPayment/PaymentForm/styles"
import { PaymentData } from "@/pages/Checkout/SelectPlanAndPayment/protocols"
import { ValidationType } from "@/hooks/useValidation"
import bankSlipImage from "@/assets/icons/barcode.svg"
import creditCardImage from "@/assets/icons/credit_card.svg"
import { Plan } from "@/protocols/plan"

const paymentMethodsCardData = [
	{
		value: "credit_card",
		image: creditCardImage,
		label: "Cartão de crédito"
	},
	{
		value: "bank_slip",
		image: bankSlipImage,
		label: "Boleto"
	}
]

type PaymentFormProps = {
	paymentData: PaymentData
	handleChangePaymentDataField: (field: keyof PaymentData, value: string) => void
	validation: ValidationType
	availablePaymentMethods: PaymentMethod[]
	selectedInstallmentCount: number
	selectedPlan?: Plan
}

const PaymentForm = ({
	paymentData,
	handleChangePaymentDataField,
	validation,
	availablePaymentMethods,
	selectedInstallmentCount,
	selectedPlan
}: PaymentFormProps) => {
	const classes = useStyles()
	const theme = useTheme()
	const aboveSmBreakpoint = useMediaQuery(theme.breakpoints.up("sm"))

	const handleChangePaymentMethod = (value: PaymentMethod) => {
		handleChangePaymentDataField("method", value)
	}

	const getDisabledMessage = (paymentMethod: PaymentMethod) => {
		if (!selectedPlan) {
			return "Forma de pagamento indisponível"
		}

		if (paymentMethod === "bank_slip") {
			if (selectedInstallmentCount > 1) {
				return "Disponível somente para pagamentos à vista"
			}

			return "Forma de pagamento indisponível para o cupom escolhido"
		}

		return "Forma de pagamento indisponível"
	}

	const renderPaymentMethodCard = (image: string, label: string) => {
		return (
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				spacing={2}
			>
				<Grid item >
					<img src={image} alt={label} className={classes.img} />
				</Grid>

				<Grid item >
					<Typography variant="body1">
						{label}
					</Typography>
				</Grid>
			</Grid>
		)
	}

	return (
		<Portlet elevation={1} >
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h3">
						Forma de pagamento
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<CardSelect
						items={paymentMethodsCardData.map(method => ({
							value: method.value,
							data: method,
							isDisabled: !availablePaymentMethods.some(availableMethod => availableMethod === method.value),
							disabledMessage: getDisabledMessage(method.value as PaymentMethod)
						}))}
						value={paymentData.method}
						render={({ data }) => renderPaymentMethodCard(data?.image as string, data?.label as string)}
						onChange={handleChangePaymentMethod}
						width={aboveSmBreakpoint ? "180px" : "50%"}
						padding="12px"
					/>
				</Grid>

				{paymentData.method === "bank_slip" && (
					<Grid item xs={12}>
						<Typography variant="h3">
							Ao finalizar o pedido, será gerado um boleto.
						</Typography>

						<Divider orientation="horizontal" size={1} />

						<Typography variant="body2">
							A sua conta será ativada após a confirmação do pagamento do boleto.
						</Typography>
					</Grid>
				)}

				{
					paymentData.method && (
						<Grid item xs={12}>
							{
								paymentData.method === "credit_card" && (

									<Grid container spacing={2}>
										<Grid item md={8} sm={12} xs={12}>
											<InputLabel htmlFor="credit_card_number">
												Número do cartão de crédito
											</InputLabel>

											<TextField
												id="credit_card_number"
												fullWidth
												variant="outlined"
												value={paymentData?.credit_card_number}
												onChange={(event) => handleChangePaymentDataField("credit_card_number", formatCreditCardNumber(event.target.value))}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<LockIcon style={{ color: colors.greyScale[6] }} />
														</InputAdornment>
													)
												}}
												helperText={validation.credit_card_number}
												error={Boolean(validation.credit_card_number)}
											/>
										</Grid>

										<Grid item md={5} sm={7} xs={12}>
											<InputLabel htmlFor="credit_card_expiration">
												Validade
											</InputLabel>

											<TextField
												id="credit_card_expiration"
												fullWidth
												variant="outlined"
												placeholder="MM/AAAA"
												value={paymentData?.expiration_date}
												onChange={(event) => handleChangePaymentDataField("expiration_date", formatCreditCardExpirationDate(event.target.value))}
												helperText={validation.expiration_date}
												error={Boolean(validation.expiration_date)}
											/>
										</Grid>

										<Grid item md={3} sm={5} xs={12}>
											<InputLabel htmlFor="credit_card_cvv">
												Código de segurança
											</InputLabel>

											<TextField
												id="credit_card_cvv"
												fullWidth
												variant="outlined"
												value={paymentData?.verification_value}
												onChange={(event) => handleChangePaymentDataField("verification_value", formatCreditCardVerificationValue(event.target.value))}
												helperText={validation.verification_value}
												error={Boolean(validation.verification_value)}
											/>
										</Grid>

										<Grid item md={8} sm={12} xs={12}>
											<InputLabel htmlFor="credit_card_owner_name">
												Nome do titular
											</InputLabel>

											<TextField
												id="credit_card_owner_name"
												fullWidth
												variant="outlined"
												value={paymentData?.credit_card_owner_name}
												onChange={(event) => handleChangePaymentDataField("credit_card_owner_name", event.target.value)}
												helperText={validation.credit_card_owner_name}
												error={Boolean(validation.credit_card_owner_name)}
											/>
										</Grid>
									</Grid>
								)}

							{paymentData.method === "bank_slip" && (
								<Grid container spacing={2}>
									<Grid item md={8} sm={12} xs={12}>
										<InputLabel htmlFor="cpf_cnpj">
											CPF ou CNPJ
										</InputLabel>

										<TextField
											id="cpf_cnpj"
											fullWidth
											variant="outlined"
											value={paymentData?.cpf_cnpj}
											onChange={(event) => handleChangePaymentDataField("cpf_cnpj", formatCpfOrCnpj(event.target.value))}
											helperText={validation.cpf_cnpj}
											error={Boolean(validation.cpf_cnpj)}
										/>
									</Grid>

									<Grid item md={5} sm={7} xs={12}>
										<InputLabel htmlFor="zipcode">
											CEP
										</InputLabel>

										<TextField
											id="zipcode"
											fullWidth
											variant="outlined"
											value={paymentData?.zip_code}
											onChange={(event) => handleChangePaymentDataField("zip_code", formatZipCode(event.target.value))}
											helperText={validation.zip_code}
											error={Boolean(validation.zip_code)}
										/>
									</Grid>

									<Grid item md={3} sm={5} xs={12}>
										<InputLabel htmlFor="address_number">
											Número da residência
										</InputLabel>

										<TextField
											id="address_number"
											fullWidth
											variant="outlined"
											value={paymentData?.address_number}
											onChange={(event) => handleChangePaymentDataField("address_number", formatInteger(event.target.value))}
											helperText={validation.address_number}
											error={Boolean(validation.address_number)}
										/>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
			</Grid>
		</Portlet>
	)
}

export default PaymentForm
