import IMask from "imask"
import { toOnlyNumbers } from "@/utils/number"

export const formatPhoneNumber = (rawNumber: string) => {
	let formattedPhoneNumber: string = rawNumber

	if (rawNumber) {
		const phoneNumberMask = IMask.createMask({
			mask: "+55 (00) 00000-0000"
		})

		formattedPhoneNumber = phoneNumberMask.resolve(rawNumber)
	}

	return formattedPhoneNumber
}

export const formatUrl = (rawUrl: string) => {
	let formattedUrl: string = rawUrl

	if (rawUrl) {
		const validPrefixes = ["https", "http"]

		// eslint-disable-next-line
		const asterisks = [...new Array(rawUrl.length)].map(_ => "*").join("")

		const defaultPrefix = validPrefixes[0]

		const currentUsedPrefixIndex = validPrefixes.findIndex(prefix => rawUrl.startsWith(prefix))

		let prefix: string

		if (currentUsedPrefixIndex === -1) {
			prefix = defaultPrefix
		} else {
			prefix = validPrefixes[currentUsedPrefixIndex]
		}

		const urlMask = IMask.createMask({
			mask: `${prefix}://${asterisks}`
		})

		formattedUrl = urlMask.resolve(formattedUrl)

		formattedUrl = formattedUrl
			.replace(/\s/g, "")
	}

	return formattedUrl
}

export const formatEmail = (rawEmail: string) => {
	let formattedEmail: string = rawEmail

	if (rawEmail) {
		formattedEmail = rawEmail
			.toLowerCase()
			.replace(/\s/g, "")
	}

	return formattedEmail
}

export const formatPartiallyHiddenEmail = (rawEmail: string) => {
	if (!rawEmail) {
		return ""
	}

	const emailSeparator = "@"
	const brokenEmail = rawEmail.split(emailSeparator)
	const expectedEmailPieces = 2

	if (brokenEmail.length !== expectedEmailPieces) {
		return rawEmail
	}

	const firstPiece = brokenEmail[0]
	const lastPiece = brokenEmail[1]

	const normalCharsOnFirstPiece = 5
	const firstCharactersOnFirstPiece = firstPiece.slice(0, normalCharsOnFirstPiece)

	const hiddenChars = rawEmail.length - firstCharactersOnFirstPiece.length
	const lastCharactersOnFirstPiece = new Array(hiddenChars).fill("*").join("")

	return `${firstCharactersOnFirstPiece}${lastCharactersOnFirstPiece}${emailSeparator}${lastPiece}`
}

export const formatCreditCardNumber = (rawNumber: string) => {
	let formattedNumber: string = rawNumber

	if (rawNumber) {
		const numberMask = IMask.createMask({
			mask: "0000 0000 0000 0000"
		})

		formattedNumber = numberMask.resolve(rawNumber)
	}

	return formattedNumber
}

export const formatCreditCardVerificationValue = (rawNumber: string) => {
	let formattedNumber: string = rawNumber

	if (rawNumber) {
		const verificationValueMask = IMask.createMask({
			mask: "000[0]"
		})

		formattedNumber = verificationValueMask.resolve(rawNumber)
	}

	return formattedNumber
}

export const formatCreditCardExpirationDate = (rawDate: string) => {
	let formattedDate: string = rawDate

	if (rawDate) {
		const dateMask = IMask.createMask({
			mask: "MM/YYYY",
			blocks: {
				MM: {
					mask: "00"
				},
				YYYY: {
					mask: "0000"
				}
			}
		})

		formattedDate = dateMask.resolve(rawDate)
	}

	return formattedDate
}

export const formatZipCode = (rawNumber: string) => {
	let formattedNumber: string = rawNumber

	if (rawNumber) {
		const zipcodeMask = IMask.createMask({
			mask: "00.000-000"
		})

		formattedNumber = zipcodeMask.resolve(rawNumber)
	}

	return formattedNumber
}

export const formatInteger = (rawNumber: string) => {
	let formattedNumber: string = rawNumber

	if (rawNumber) {
		const integerMask = IMask.createMask({
			mask: Number,
			scale: 0,
			signed: false
		})

		formattedNumber = integerMask.resolve(rawNumber)
	}

	return formattedNumber
}

export const formatCpfOrCnpj = (value: string) => {
	if (!value) {
		return value
	}

	const mask = IMask.createMask({
		mask: [
			{
				mask: "000.000.000-00"
			},
			{
				mask: "00.000.000/0001-00"
			}
		]
	})

	const formattedValue = mask.resolve(toOnlyNumbers(value))
	return formattedValue
}
