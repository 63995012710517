import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(({
	container: {
		height: 56,
		width: "100%",
		background: "#0E1726"
	},
	textWhite: {
		color: "#ffffff"
	},
	textTitle: {
		textTransform: "uppercase",
		fontWeight: 600
	},
	textName: {
		fontWeight: 600
	},
	profile: {
		cursor: "pointer"
	},
	logo: {
		cursor: "pointer"
	},
	logoImg: {
		width: 87
	}
}))

export default useStyles
