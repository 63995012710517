import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	appCardContainer: {
		height: "260px",
		width: "100%",
		maxWidth: "230px",
		border: `1px solid ${colors.greyScale[2]}AA`,
		boxSizing: "border-box"
	},
	appCardAction: {
		display: "flex",
		flexDirection: "column",
		height: "100%"
	},
	appCardContent: {
		padding: theme.spacing(2, 2, 0)
	},
	appImage: {
		height: "100%",
		width: "auto",
		maxHeight: "90px",

		margin: "0 auto"
	},
	appTitle: {
		fontWeight: 600,
		textTransform: "uppercase"
	}
}))

export default useStyles
