import { Divider, Loading, Portlet } from "@/components"
import { Link, Typography, Grid, Divider as MuiDivider, Chip, useMediaQuery, useTheme } from "@material-ui/core"
import React, { useState } from "react"
import ApiService from "@/services/Api"
import useDidMount from "@/hooks/useDidMount"
import useValidation from "@/hooks/useValidation"
import { PaymentMethod } from "@/protocols/payment"
import { transformCentsInDefaultPattern } from "@/utils/money"
import { formatDate } from "@/utils/date"
import colors from "@/styles/colors"
import SubscriptionsCardsSkeleton from "@/skeletons/Profile/SubscriptionsCardsSkeleton"

type SubscriptionStatus = "active" | "inactive" | "expired"

type SubscriptionSummary = {
	expiration_date: Date
	id: number
	is_free: boolean
	last_payment_date: Date
	last_payment_invoice: string
	last_payment_value_in_cents: number
	next_payment_date: Date
	next_payment_invoice: string
	next_payment_value_in_cents: number
	payment_method: PaymentMethod
	periodicity_in_months: number
	plan_id: number
	plan_name: string
	price_in_cents: number
	status: SubscriptionStatus
}

const SubscriptionStatusMap = {
	active: {
		label: "ativo",
		backgroundColor: colors.palette.confirm,
		color: colors.greyScale[11]
	},
	inactive: {
		label: "inativo",
		backgroundColor: colors.greyScale[6],
		color: colors.greyScale[1]
	},
	expired: {
		label: "expirado",
		backgroundColor: colors.palette.tag3,
		color: colors.greyScale[1]
	}
}

const Subscriptions = () => {
	const { triggerUndeterminedValidation } = useValidation()

	const [loading, setLoading] = useState(true)
	const [subscriptionsData, setSubscriptionsData] = useState<SubscriptionSummary[]>([])

	const theme = useTheme()
	const aboveXsBreakpoint = useMediaQuery(theme.breakpoints.up("xs"))

	const getAndUpdateSubscriptionsData = async () => {
		try {
			const response = await ApiService.get("/subscriptions/summary")
			const subscriptions = response.data
			setSubscriptionsData(subscriptions)
			setLoading(false)
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	useDidMount(() => {
		getAndUpdateSubscriptionsData()
	})

	return (
		<Grid container>
			<Divider orientation="horizontal" size={1} />

			<Grid item xs={12}>
				<Typography variant="h3">
					Assinaturas
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<Divider orientation="horizontal" size={2} />
			</Grid>

			<Grid item xs={12}>
				<Loading loading={loading} customLoadingElement={<SubscriptionsCardsSkeleton />}>
					<Grid container spacing={2}>
						{subscriptionsData?.map(subscription => {
							const statusData = SubscriptionStatusMap[subscription.status as SubscriptionStatus]
							const periodicityName = subscription.periodicity_in_months === 1 ? "/ Mensal" : "/ Anual"

							return (
								<Grid
									item
									key={subscription.id}
									style={{ width: aboveXsBreakpoint ? "432px" : "100%" }}
								>
									<Portlet elevation={1} style={{ padding: "32px 16px" }}>
										<Grid container direction="row" justify="center" spacing={3}>
											<Grid item>
												<Typography variant="h1" style={{ textTransform: "uppercase" }} >
													{subscription.plan_name} {!subscription.is_free && periodicityName}
												</Typography>
											</Grid>

											<Divider orientation="horizontal" size={1} />

											<Grid item xs={12}>
												<Grid container justify="center">
													<Chip
														size="small"
														style={{
															padding: "0 24px",
															backgroundColor: statusData.backgroundColor,
															color: statusData.color
														}}
														label={
															<Typography variant="button">
																{statusData.label}
															</Typography>
														}
													/>
												</Grid>
											</Grid>

											<Divider orientation="horizontal" size={5} />

											<Grid item xs={12}>
												<Grid container justify="space-between">
													<Grid item>
														<Typography variant="h4">
															VALOR
														</Typography>
													</Grid>

													<Grid item>
														<Typography variant="body1">
															R$ {transformCentsInDefaultPattern(subscription.price_in_cents)}
														</Typography>
													</Grid>
												</Grid>
											</Grid>

											<Grid item xs={12}>
												<MuiDivider />
											</Grid>

											<Grid item xs={12}>
												<Grid container justify="space-between" alignItems="center">
													<Grid item>
														<Grid container direction="column" alignItems="flex-start" spacing={1}>
															<Grid item >
																<Typography variant="h4">
																	ÚLTIMO PAGAMENTO
																</Typography>
															</Grid>

															{
																subscription.last_payment_invoice && (
																	<Grid item>
																		<Link target="_blank" href={subscription.last_payment_invoice} >
																			Ver fatura
																		</Link>
																	</Grid>
																)
															}
														</Grid>
													</Grid>

													<Grid item>
														<Typography variant="body1">
															<Grid container direction="column" alignItems="flex-end" spacing={1}>
																{
																	(subscription.last_payment_value_in_cents && subscription.last_payment_date) ? (
																		<>
																			<Grid item>
																				R$ {transformCentsInDefaultPattern(subscription.last_payment_value_in_cents)}
																			</Grid>

																			<Grid item>
																				{formatDate(subscription.last_payment_date)}
																			</Grid>
																		</>
																	) : (
																		<Grid item>
																			-
																		</Grid>
																	)
																}
															</Grid>
														</Typography>
													</Grid>
												</Grid>
											</Grid>

											<Grid item xs={12}>
												<MuiDivider />
											</Grid>

											<Grid item xs={12}>
												<Grid container justify="space-between" alignItems="center">
													<Grid item>
														<Grid container direction="column" alignItems="flex-start" spacing={1}>
															<Grid item>
																<Typography variant="h4">
																	PRÓXIMO PAGAMENTO
																</Typography>
															</Grid>

															{
																subscription.next_payment_invoice && (
																	<Grid item>
																		<Link target="_blank" href={subscription.next_payment_invoice} >
																			Ver fatura
																		</Link>
																	</Grid>
																)
															}
														</Grid>
													</Grid>

													<Grid item>
														<Typography variant="body1">
															<Grid container direction="column" alignItems="flex-end" spacing={1}>
																{
																	// using ternary because the parenthesis result was returning 0
																	(subscription.next_payment_value_in_cents && subscription.next_payment_date) ? (
																		<>
																			<Grid item>
																				R$ {transformCentsInDefaultPattern(subscription.next_payment_value_in_cents)}
																			</Grid>

																			<Grid item>
																				{formatDate(subscription.next_payment_date)}
																			</Grid>
																		</>
																	) : (
																		<Grid item>
																			-
																		</Grid>
																	)
																}
															</Grid>
														</Typography>
													</Grid>
												</Grid>
											</Grid>

											<Grid item xs={12}>
												<MuiDivider />
											</Grid>

											<Grid item xs={12}>
												<Grid container justify="space-between">
													<Grid item>
														<Typography variant="h4">
															FORMA DE PAGAMENTO
														</Typography>
													</Grid>

													{!subscription.is_free ? (
														<Grid item>
															<Typography variant="body1">
																{subscription.payment_method === "credit_card" ? "CARTÃO DE CRÉDITO" : "BOLETO BANCÁRIO"}
															</Typography>
														</Grid>
													) : (
														<Grid item>
															-
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</Portlet>
								</Grid>
							)
						})}
					</Grid>
				</Loading>
			</Grid>
		</Grid>
	)
}

export default Subscriptions
