import React, { useEffect, useState, useRef } from "react"
import { Container, Button, CircularProgress } from "@material-ui/core"
import useDidMount from "@/hooks/useDidMount"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import { InstallmentOption, PeriodicityOption, Plan } from "@/protocols/plan"
import useQuery from "@/hooks/useQuery"
import { castPossiblyNullToNumber } from "@/utils/number"
import { Divider } from "@/components"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import CheckoutStepper from "@/pages/Checkout/Stepper"
import Plans from "@/pages/Checkout/SelectPlanAndPayment/Plans"
import Installment from "@/pages/Checkout/SelectPlanAndPayment/Installment"
import PaymentForm from "@/pages/Checkout/SelectPlanAndPayment/PaymentForm"
import { PaymentMethod } from "@/protocols/payment"
import { PaymentData } from "@/pages/Checkout/SelectPlanAndPayment/protocols"
import useStyles from "@/pages/Checkout/SelectPlanAndPayment/styles"
import { Subscription } from "@/protocols/subscription"
import CouponAndOverview from "@/pages/Checkout/SelectPlanAndPayment/CouponAndOverview"
import { Coupon } from "@/protocols/coupon"
import { removeHyphensAndPoints } from "@/utils/regex"

const paymentMethods: PaymentMethod[] = ["credit_card", "bank_slip"]

const CheckoutSelectPlanAndPayment = () => {
	const queryString = useQuery()
	const app_id = castPossiblyNullToNumber(queryString.get("app_id"))
	const plan_id = castPossiblyNullToNumber(queryString.get("plan_id"))
	const periodicity_in_months = castPossiblyNullToNumber(queryString.get("periodicity_in_months")) || 1
	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()
	const classes = useStyles()

	const [plans, setPlans] = useState([] as Plan[])
	const [selectedPlan, setSelectedPlan] = useState<Plan>()
	const [selectedPeriodicityInMonths, setSelectedPeriodicityInMonths] = useState(periodicity_in_months)
	const [selectedInstallmentCount, setSelectedInstallmentCount] = useState(1)
	const [paymentData, setPaymentData] = useState({ method: "credit_card" } as PaymentData)
	const [awaitingCheckout, setAwaitingCheckout] = useState(false)
	const [availablePaymentMethods, setAvailablePaymentMethods] = useState([] as PaymentMethod[])
	const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>()

	const getAccountWorkspaceSubscriptions = async () => {
		const { data } = await ApiService.get("/workspace/subscriptions")
		return data as Subscription[]
	}

	const getPlansByAppId = async (appId: number) => {
		const { data } = await ApiService.get(`plans/app/${appId}`)
		return data as Plan[]
	}

	const getPlansByAppIdWithOwnInfo = async (appId: number) => {
		try {
			const [subscriptions, plans] = await Promise.all([
				getAccountWorkspaceSubscriptions(),
				getPlansByAppId(appId)
			])

			const paidPlans = plans.filter((plan) => plan.periodicity_options)
			return paidPlans.map(plan => {
				const isPlanOwn = subscriptions.some(subscription => subscription.plan_id === plan.id)
				return {
					...plan,
					already_own: isPlanOwn
				}
			})
		} catch (err) {
			ErrorHandler.handle(err)
			return []
		}
	}

	const handleChangeSelectedPlan = (planId: number) => {
		const planFound = plans.find(plan => plan.id === planId) as Plan
		setSelectedPlan(planFound)
	}

	const handleChangeSelectedInstallmentCount = (newInstallmentCount: number) => {
		setSelectedInstallmentCount(newInstallmentCount)
	}

	const handleChangeSelectedPeriodicityInMonths = (periodicityInMonths: number) => {
		setSelectedPeriodicityInMonths(periodicityInMonths)
		handleChangeSelectedInstallmentCount(1)
	}

	const handleChangePaymentDataField = (field: keyof PaymentData, value: string) => {
		clearValidation(field)

		setPaymentData({
			...paymentData,
			[field]: value
		})
	}

	const getPaymentMethodsAllowedByCoupon = () => {
		const couponInfoByPlan = selectedCoupon?.coupon_in_plans?.find(couponInPlan => couponInPlan.plan_id === selectedPlan?.id)

		const couponPeriodicityOption = couponInfoByPlan?.periodicity_options.find(option => option.periodicity_in_months === selectedPeriodicityInMonths)

		const couponInstallmentData = couponPeriodicityOption?.installment_options.find(option => option.count === selectedInstallmentCount)

		if (couponInstallmentData) {
			const allowedPaymentMethods: PaymentMethod[] = []
			Object.entries(couponInstallmentData.payment_methods).forEach(([method, isAllowed]) => {
				if (isAllowed) {
					allowedPaymentMethods.push(method as PaymentMethod)
				}
			})

			return allowedPaymentMethods
		}

		return null
	}

	const getAvailablePaymentMethods = (paymentMethods: PaymentMethod[], installmentData: InstallmentOption) => {
		const paymentMethodsAllowedByPlanAndPeriodicity = paymentMethods.filter(method => installmentData?.payment_methods[method])
		const paymentMethodsAllowedByCoupon = getPaymentMethodsAllowedByCoupon()

		if (paymentMethodsAllowedByCoupon) {
			const availablePaymentMethods = paymentMethodsAllowedByPlanAndPeriodicity.filter(value => paymentMethodsAllowedByCoupon.includes(value))

			return availablePaymentMethods
		}

		return paymentMethodsAllowedByPlanAndPeriodicity
	}

	// eslint-disable-next-line
	const updateAvailablePaymentMethods = useRef<Function>(() => { })

	updateAvailablePaymentMethods.current = () => {
		getPaymentMethodsAllowedByCoupon()
		const periodicityOption = selectedPlan?.periodicity_options?.find(option => option.periodicity_in_months === selectedPeriodicityInMonths) as PeriodicityOption

		const installmentData = periodicityOption?.installment_options.find(option => option.count === selectedInstallmentCount) as InstallmentOption

		const availablePaymentMethods = getAvailablePaymentMethods(
			paymentMethods,
			installmentData
		)

		setAvailablePaymentMethods(availablePaymentMethods)
	}

	// eslint-disable-next-line
	const updatePaymentMethodIfNecessary = useRef<Function>(() => { })

	updatePaymentMethodIfNecessary.current = () => {
		if (availablePaymentMethods.length > 1 && !paymentData.method) {
			handleChangePaymentDataField("method", availablePaymentMethods[0])
			return
		}

		if (availablePaymentMethods.length === 1) {
			handleChangePaymentDataField("method", availablePaymentMethods[0])
			return
		}

		if (availablePaymentMethods.length === 0) {
			// Used setPaymentData because the input type of handleChangePaymentDataField
			setPaymentData({
				...paymentData,
				method: undefined
			})
		}
	}

	const processValidationResponse = (codeMessages: Record<string, string>) => {
		const rawCodeMessages = {
			month: codeMessages?.month,
			year: codeMessages?.year,
			expiration_date: codeMessages?.expiration_date,
			number: codeMessages?.number,
			first_name: codeMessages?.first_name,
			last_name: codeMessages?.last_name
		}

		const newMessages: Record<string, string> = {}

		let expirationDateMessage: string | null = null
		if (rawCodeMessages.month === "field_cannot_be_empty") {
			expirationDateMessage = "field_cannot_be_empty"
		} else if (rawCodeMessages.month || rawCodeMessages.year) {
			expirationDateMessage = "invalid_expiration_date"
		} else if (rawCodeMessages.expiration_date) {
			expirationDateMessage = rawCodeMessages.expiration_date
		}

		if (expirationDateMessage) {
			newMessages.expiration_date = expirationDateMessage
		}

		if (rawCodeMessages.number) {
			const errorKey = paymentData.method === "credit_card" ? "credit_card_number" : "address_number"
			newMessages[errorKey] = rawCodeMessages.number
		}

		if (rawCodeMessages.first_name) {
			newMessages.credit_card_owner_name = rawCodeMessages.first_name
		} else if (rawCodeMessages.last_name === "field_cannot_be_empty") {
			newMessages.credit_card_owner_name = "last_name_cannot_be_empty"
		}

		return newMessages
	}

	const makeCheckout = async () => {
		const splittedName = paymentData?.credit_card_owner_name?.split(" ") || []
		const first_name = splittedName[0] || ""
		const last_name = splittedName.length > 1 ? splittedName[splittedName.length - 1] : ""

		const splittedDate = paymentData?.expiration_date?.split("/") || []
		const month = splittedDate[0] || ""
		const year = splittedDate[1] || ""

		const creditCardNumber = paymentData?.credit_card_number?.replace(/\s/g, "")

		const zipCode = removeHyphensAndPoints(paymentData?.zip_code)

		const payload = {
			plan_id: selectedPlan?.id,
			periodicity_in_months: selectedPeriodicityInMonths,
			installment_count: selectedInstallmentCount,
			payment: {
				method: paymentData.method,
				number: paymentData.method === "credit_card"
					? creditCardNumber
					: paymentData?.address_number,
				verification_value: paymentData.verification_value,
				month,
				year,
				first_name,
				last_name,
				zip_code: zipCode,
				cpf_cnpj: paymentData.cpf_cnpj
			},
			coupon_id: selectedCoupon?.id
		}

		try {
			const { data } = await ApiService.post("checkout", payload)
			return data.subscription.id
		} catch (error) {
			const typedError = error as ErrorType

			if (typedError?.response?.data?.codeMessages) {
				// eslint-disable-next-line
				const newMessages = processValidationResponse(typedError.response.data.codeMessages as any)
				typedError.response.data.codeMessages = {
					...typedError.response.data.codeMessages,
					...newMessages
				}
			}

			triggerValidation(typedError)
			return null
		}
	}

	const saveOrganizationExtraData = async () => {
		const payload = {
			cpf_cnpj: paymentData.cpf_cnpj,
			address: {
				zip_code: paymentData.zip_code,
				number: paymentData.address_number
			}
		}

		try {
			ApiService.put("/organization/extra_data", payload)
		} catch (err) { }
	}

	const goToProcessingPaymentPage = (subscriptionId: number) => {
		window.location.href = `/checkout/processing-payment/${subscriptionId}`
	}

	const handleSubmitFinishCheckout = async () => {
		setAwaitingCheckout(true)

		const [subscriptionId] = await Promise.all([
			makeCheckout(),
			saveOrganizationExtraData()
		])

		if (subscriptionId) {
			window.onbeforeunload = null
			goToProcessingPaymentPage(subscriptionId)
		}

		setAwaitingCheckout(false)
	}

	useDidMount(() => {
		const didMount = async () => {
			const availablePlans = await getPlansByAppIdWithOwnInfo(app_id)
			setPlans(availablePlans)
			const initialPlan = availablePlans.find(plan => plan.id === plan_id)

			if (initialPlan && !initialPlan.already_own) {
				setSelectedPlan(initialPlan)
			}
		}

		didMount()
	})

	useEffect(() => {
		updateAvailablePaymentMethods.current()
	}, [selectedPlan, selectedPeriodicityInMonths, selectedInstallmentCount, updateAvailablePaymentMethods, selectedCoupon])

	useEffect(() => {
		updatePaymentMethodIfNecessary.current()
	}, [updatePaymentMethodIfNecessary, availablePaymentMethods])

	const handleSendCheckoutAbandonedEvent = async () => {
		await ApiService.post("/events/checkout/abandoned", { plan_id: selectedPlan?.id })
	}

	useEffect(() => {
		window.onbeforeunload = handleSendCheckoutAbandonedEvent
		// eslint-disable-next-line
	}, [selectedPlan])

	useEffect(() => {
		return () => {
			window.onbeforeunload = null
		}
		// eslint-disable-next-line
	}, [])

	return (
		<Container maxWidth="md" disableGutters>
			<CheckoutStepper activeStep={0} />

			<Divider orientation="horizontal" size={4} />

			<Plans
				plans={plans}
				selectedPlan={selectedPlan}
				handleChangeSelectedPlan={handleChangeSelectedPlan}
				selectedPeriodicityInMonths={selectedPeriodicityInMonths}
				handleChangeSelectedPeriodicityInMonths={handleChangeSelectedPeriodicityInMonths}
			/>

			<Divider orientation="horizontal" size={6} />

			{
				(selectedPeriodicityInMonths > 1 && selectedPlan) && (
					<>
						<Installment
							selectedInstallmentCount={selectedInstallmentCount}
							handleChangeSelectedInstallmentCount={handleChangeSelectedInstallmentCount}
							selectedPlan={selectedPlan}
							selectedPeriodicityInMonths={selectedPeriodicityInMonths}
						/>

						<Divider orientation="horizontal" size={6} />
					</>
				)
			}

			<CouponAndOverview
				selectedCoupon={selectedCoupon}
				handleChangeSelectedCoupon={setSelectedCoupon}
				selectedInstallmentCount={selectedInstallmentCount}
				selectedPlan={selectedPlan}
				selectedPeriodicityInMonths={selectedPeriodicityInMonths}
			/>

			<Divider orientation="horizontal" size={6} />

			<PaymentForm
				paymentData={paymentData}
				handleChangePaymentDataField={handleChangePaymentDataField}
				validation={validation}
				availablePaymentMethods={availablePaymentMethods}
				selectedInstallmentCount={selectedInstallmentCount}
				selectedPlan={selectedPlan}
			/>

			<Divider orientation="horizontal" size={6} />

			<Button
				fullWidth
				variant="contained"
				color="primary"
				onClick={handleSubmitFinishCheckout}
				endIcon={awaitingCheckout && <CircularProgress size={20} className={classes.circularProgress} />}
			>
				Pagar
			</Button>

			<Divider orientation="horizontal" size={6} />
		</Container >
	)
}

export default CheckoutSelectPlanAndPayment
