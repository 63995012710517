import React from "react"
import { Stepper, Step, StepLabel } from "@material-ui/core"
import { QontoConnector, QontoStepIcon } from "@/pages/Checkout/Stepper/styles"

const steps = [
	"Definir plano e forma de pagamento",
	"Processando o pagamento",
	"Assinatura ativada"
]

export type CheckoutStepperProps = {
	activeStep: 0 | 1 | 2
}

const CheckoutStepper = ({ activeStep }: CheckoutStepperProps) => {
	return (
		<Stepper
			style={{
				backgroundColor: "transparent"
			}}
			activeStep={activeStep}
			alternativeLabel
			connector={<QontoConnector />}
		>
			{steps.map((label) => (
				<Step key={label}>
					<StepLabel StepIconComponent={QontoStepIcon} >
						{label}
					</StepLabel>
				</Step>
			))}
		</Stepper>

	)
}

export default CheckoutStepper
