import React from "react"
import { Grid, Typography, Select, MenuItem } from "@material-ui/core"
import { Plan } from "@/protocols/plan"
import { transformCentsInDefaultPattern } from "@/utils/money"
import { Portlet } from "@/components"

type InstallmentProps = {
	selectedInstallmentCount: number
	handleChangeSelectedInstallmentCount: (installmentCount: number) => void
	selectedPlan?: Plan
	selectedPeriodicityInMonths: number
}

const Installment = ({
	selectedInstallmentCount,
	handleChangeSelectedInstallmentCount,
	selectedPlan,
	selectedPeriodicityInMonths
}: InstallmentProps) => {
	const getInstallmentsMenuItems = (plan: Plan | undefined, periodicityInMonths: number) => {
		const periodicityOption = plan?.periodicity_options?.find(option => option.periodicity_in_months === periodicityInMonths)

		const menuItems = periodicityOption?.installment_options?.map(option => {
			const totalPriceInCents = (selectedPlan?.price_in_cents || 0) * selectedPeriodicityInMonths
			const installmentPriceInCents = Math.floor(totalPriceInCents / option.count)

			return (
				<MenuItem key={option?.count} value={option?.count}>
					{option?.count}x de R$ {transformCentsInDefaultPattern(installmentPriceInCents)} (R$ ${transformCentsInDefaultPattern(totalPriceInCents)} )
				</MenuItem>
			)
		})

		return menuItems
	}

	return (
		<Portlet elevation={1} >
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h3" >
						Parcelamento
					</Typography>
				</Grid>

				<Grid item md={5} sm={8} xs={12}>
					<Select
						fullWidth
						variant="outlined"
						value={selectedInstallmentCount}
						onChange={(event) => handleChangeSelectedInstallmentCount(event.target.value as number)}
					>
						{
							getInstallmentsMenuItems(selectedPlan, selectedPeriodicityInMonths)
						}

					</Select>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default Installment
