import React from "react"

import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core"
import { Divider } from "@/components"
import { AppName } from "@/protocols/app"

import useStyles from "@/pages/WizardApp/Components/AppCard/styles"

type AppCardProps = {
	image: string
	appName: AppName
	description: string
	onSelect: () => void
}

const AppCard = (props: AppCardProps) => {
	const {
		appName,
		image,
		description,
		onSelect
	} = props

	const classes = useStyles()

	const handleSelectAppChange = () => {
		onSelect()
	}

	return (
		<Card
			className={classes.appCardContainer}
		>
			<CardActionArea
				component="a"
				href={`#redirect-to-${appName}`}
				className={classes.appCardAction}
				onClick={handleSelectAppChange}
			>
				<CardMedia
					component="img"
					alt={`Selecionar a aplicação: ${appName}`}
					image={image}
					title={appName}
					className={classes.appImage}
				/>

				<CardContent className={classes.appCardContent}>
					<Typography
						align="center"
						className={classes.appTitle}
						variant="body1">
						{appName}
					</Typography>

					<Divider size={1} orientation="horizontal" />

					<Typography align="center" variant="body2">{description}</Typography>
				</CardContent>

			</CardActionArea>
		</Card>
	)
}

export default AppCard
