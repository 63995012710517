import React, { useState } from "react"
import { useHistory } from "react-router"

import {
	Container,
	Grid,
	InputAdornment,
	IconButton,
	Button,
	Typography,
	Link,
	CircularProgress,
	TextField
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import { Divider, InfoDialog, Portlet } from "@/components"
import useStyles from "@/pages/Auth/styles"
import ApiService from "@/services/Api"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import AuthService from "@/services/Auth"
import useQuery from "@/hooks/useQuery"
import useDidMount from "@/hooks/useDidMount"

import { LocationState } from "@/protocols/history"

import logoImg from "@/assets/images/logos/letalk-logo.svg"
import { formatEmail } from "@/utils/mask"
import { bumeLinks } from "@/utils/link"

type SignInDataType = {
	email: string
	password: string
}

const SignIn = () => {
	const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
	const [signInData, setSignInData] = useState<SignInDataType>({} as SignInDataType)
	const [loadingSignIn, setLoadingSignIn] = useState<boolean>(false)

	const [oldBumeWarningOpenDialog, setOldBumeOpenDialog] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation,
		addValidation
	} = useValidation()
	const query = useQuery()
	const classes = useStyles()
	const history = useHistory()

	const app_code = query.get("app_code")

	const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoadingSignIn(true)

		try {
			const containInMailingOldBume = await AuthService
				.containInMailingOldBume(signInData.email)

			if (containInMailingOldBume) {
				setOldBumeOpenDialog(true)

				addValidation({
					email: "Esse e-mail pertence ao BUME 1.0"
				})
			} else {
				const { data } = await ApiService.post("/auth/signin", {
					email: signInData.email,
					password: signInData.password,
					context_url: {
						desiredContext: {
						// ...((app_code && app_code !== "null") && { relatedApplication: app_code })
							relatedApplication: "inbox_app"
						},
						currentContext: {
							relatedApplication: "account",
							pathUrl: window.location.href
						}
					}
				})

				const {
					redirectPathUrl
				} = data

				let lastUrlAccessedBeforeLogin = (history.location?.state as LocationState)?.prevUrl

				if (lastUrlAccessedBeforeLogin && lastUrlAccessedBeforeLogin.includes("wizard-app")) {
					lastUrlAccessedBeforeLogin = undefined
				}

				AuthService
					.login(lastUrlAccessedBeforeLogin || redirectPathUrl)
			}
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoadingSignIn(false)
	}

	const handleSignInDataChange = (type: string, value: string) => {
		setSignInData({ ...signInData, [type]: value })

		clearValidation(type)
	}

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const checkIfLoggedIn = async () => {
		const { data } = await ApiService.get("/account", {
			params: {
				app_code
			}
		})

		if (data) {
			try {
				const { data } =	await ApiService
					.post("/redirect/app", {
						context_url: {
							desiredContext: {
								// ...((app_code && app_code !== "null") && { relatedApplication: app_code })
								relatedApplication: "inbox_app"
							},
							currentContext: {
								relatedApplication: "account",
								pathUrl: window.location.href
							}
						}
					})

				if (data?.pathToRedirect) {
					window.location.href = data.pathToRedirect
				} else {
					window.location.replace("/wizard-app")
				}
			} catch (error) {
				triggerValidation(error as ErrorType)
			}
		}
	}

	const handleOldBumeDialogClose = () => {
		setOldBumeOpenDialog(false)
	}

	useDidMount(() => {
		checkIfLoggedIn()
	})

	return (
		<Container maxWidth={"md"}>
			<Grid container justify="center" className={classes.authContainer}>
				<Grid item xs={12} sm={10} md={9}>
					<Grid container justify="center" className={classes.authContent}>
						<Grid item xs={12} sm={10} md={9} className={classes.authWrapper}>
							<Portlet elevation={1} className={classes.portlet}>
								<img src={logoImg} alt="Letalk" className={classes.logoImg} />

								<Divider size={4} orientation="horizontal" />

								<form onSubmit={handleSignIn}>
									<TextField
										id="input-email"
										name="email"
										value={signInData.email}
										onChange={
											({ target }) => handleSignInDataChange(
												"email", formatEmail(target.value)
											)
										}
										variant="outlined"
										label="Email"
										fullWidth
										helperText={validation.email}
										error={Boolean(validation.email)}
									/>

									<Divider orientation="horizontal" size={2}/>

									<TextField
										id="input-password"
										type={visiblePassword ? "text" : "password"}
										value={signInData.password}
										onChange={
											({ target }) => handleSignInDataChange("password", target.value)
										}
										label="Senha"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="alterar visibilidade da senha"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{visiblePassword ? <ShowPassword /> : <HiddenPassword />}
													</IconButton>
												</InputAdornment>
											)
										}}
										fullWidth
										variant="outlined"
										helperText={validation.password}
										error={Boolean(validation.password)}
									/>
									<Divider orientation="horizontal" size={1} />

									<Grid item style={{ width: "100%" }}>
										<Grid container justify="flex-end" alignItems="center">
											<Typography
												variant="body1"
												align="right"
											>
										Esqueceu a senha?
											</Typography>

											<Divider orientation="vertical" size={1} />

											<Link href="/recovery/request" underline="always" color="inherit">
											Clique aqui
											</Link>
										</Grid>
									</Grid>

									<Divider orientation="horizontal" size={4}/>

									<Button
										color="primary"
										variant="contained"
										type='submit'
										disabled={loadingSignIn}
										endIcon={loadingSignIn && (
											<CircularProgress size={20} color="inherit" />
										)}
										fullWidth
										className={classes.submitButton}
									>
									Entrar
									</Button>

									{/*
									<Divider orientation="horizontal" size={1.5}/>

									<Typography align="center">
									Ainda não tem uma conta?{" "}
										<Link href={`/signup${app_code ? `?app_code=${app_code}` : ""}`} underline="always">Cadastrar</Link>
									</Typography> */}
								</form>
							</Portlet>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<InfoDialog
				openDialog={oldBumeWarningOpenDialog}
				onClose={handleOldBumeDialogClose}
				maxWidth="xs"
			>
				<Grid container spacing={3} justify="center">
					<Grid item xs={12}>
						<Typography
							align="center"
							variant="h4"
						>
							Esse e-mail pertence ao BUME 1.0
						</Typography>
					</Grid>
					<Grid item>
						<Button
							component="a"
							href={bumeLinks.signIn}
							target="_BLANK"
							variant="contained"
							color="primary"
							disableFocusRipple
							disableElevation
						>
							Fazer login no Bume 1.0
						</Button>
					</Grid>
				</Grid>
			</InfoDialog>
		</Container>
	)
}

export default SignIn
