import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, useTheme, useMediaQuery } from "@material-ui/core"

const SubscriptionsCardsSkeleton = () => {
	const theme = useTheme()
	const aboveXsBreakpoint = useMediaQuery(theme.breakpoints.up("xs"))

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				style={{ width: aboveXsBreakpoint ? "432px" : "100%" }}
			>
				<Skeleton
					width="100%"
					height="500px"
					style={{ transform: "scale(1)" }}
					animation="wave"
				/>
			</Grid>
		</Grid>
	)
}

export default SubscriptionsCardsSkeleton
